// GlobalState.js
import React, { createContext, useState, useContext } from 'react';

// Create a context and initial state
const GlobalContext = createContext();

const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({});

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalState = () => {
  return useContext(GlobalContext);
};

export { GlobalStateProvider, useGlobalState };