import { React, useState, useEffect } from 'react';

import useToken from '../../util/useToken';

import { getAllOAs } from '../../util/api';
import { permDeleteOA } from '../../util/api';

import './DeleteOA.css';

export default function DeleteOA () {
    const { token, setToken, removeToken, validToken} = useToken();
    const [ OAs, setOAs ] = useState({"results":[]})
    const [ selectedId, setSelectedId ] = useState(null);
    const [ refresh, setRefresh ] = useState(false);

    //Pagination variables
    const [ page, setPage ] = useState(1) 
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(5)
    const [ resultCount, setResultCount ] = useState(0);

    useEffect(() => {
        const handleOAs = async () => {
            let info = await getAllOAs(token, page, pageSize)
            setOAs(info)
            setResultCount(parseInt(info["resultCount"]))

        }
        handleOAs()
        setRefresh(false)
    }, [page, refresh, pageSize])


    const handleDelete = () => {
        permDeleteOA(token, selectedId).then(response => {
            response.json().then(json => {
                if(response.status !== 200){
                    alert(json["message"])
                }else{
                    setRefresh(true)
                }
            })
        })
    }

    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id)
        }
    };

    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }

    return (
        <div className="container">
            <div className="OA-container">
                <h2>OAs</h2>
                <div>
                    <div className="OA-table-buttons">
                        <div>
                            <button disabled={selectedId == null} onClick={() => {handleDelete();}}>Delete</button>
                        </div>
                        <div>
                            <button onClick={() => {handleBackClick()}}>{"<"}</button>
                            <input
                                className="page-input"
                                type="number"
                                value={editedPage}
                                onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                                onBlur={(event) => {
                                    const enteredPage = event.target.valueAsNumber || editedPage;
                                    const maxPage = Math.ceil(resultCount / pageSize);
                                    setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                    setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                }}
                            />
                            <button onClick={() => {handleFowardClick()}}>{">"}</button>
                        </div>
                    </div>
                    <table className="OA-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>PH Treatment</th>
                                <th>Maintenances</th>
                                <th>Transcriptomics</th>
                                <th>X-Rays</th>
                            </tr>
                        </thead>
                        <tbody>
                            {OAs?.results?.map((result) => (
                            <tr key={result.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedId === result.id}
                                        onChange={event => handleCheckboxChange(event, result.id)}
                                    />
                                </td>
                                <td>{result.name}</td>
                                <td>{result.phTreatment}</td>
                                <td>{result.OAMaintenances?.length}</td>
                                <td>{result.transcriptomics?.length}</td>
                                <td>{result.OAXrays?.length}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
            </div>
        </div>
    );
} 