import React from 'react';

const TextInput = ({ value, onChange, editable, label="", viewAsSeq=false }) => {
  const handleChange = (event) => {
    if(onChange != undefined){
      onChange(event);
    }
  };

  const formatToFasta = (text) => {
    const sequenceIdentifier = '>\n';

    const chunkSize = 60;
    let formattedText = '';
    for (let i = 0; i < text?.length; i += chunkSize) {
      const chunk = text.substring(i, i + chunkSize);
      formattedText += chunk + '\n';
    }

    return sequenceIdentifier + formattedText;
  };

  return (
    <div className="input-alignment">
      <label className="input-alignment-item-left" htmlFor="input"><b>{label}</b></label>
        {(
          !editable ? (viewAsSeq ? <pre>{formatToFasta(value)}</pre> : value) : 
          <input
            id="input"
            className="input-alignment-item-right"
            type="text"
            value={value}
            onChange={(event) => handleChange(event)}
          />
        )}
    </div>
  );
};

export default TextInput;