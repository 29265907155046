import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useToken from '../../util/useToken';

import { getCommunityAnalysis } from '../../util/api';
import { addCommunityAnalysis } from '../../util/api';
import { deleteCommunityAnalysis } from '../../util/api';
import { updateCommunityAnalysis } from '../../util/api';
import { getFilterOptions } from '../../util/api';
import NumberInput from '../NumberInput';
import DateTimeInput from '../DateTimeInput';
import DropDownInput from '../DropDownInput';
import TextInput from '../TextInput';
import PrimerPairInput from '../inputs/PrimerPairInput';

import { format } from 'date-fns';

import './CommunityAnalysis.css'
import { setRef } from '@material-ui/core';

export default function CommunityAnalysis ( { sampleID } ) {
    const { token, setToken, removeToken, validToken} = useToken();
    const [ firstLoad, setFirstLoad ] = useState(true);
    const loadingGIF = require('../../content/loading.gif');
    const [ communityAnalysis, setCommunityAnalysis ] = useState({"results":[]});
    const [ editMode, setEditMode ] = useState(false);
    const [ viewMode, setViewMode ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ refresh, setRefresh ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    //Pagination variables
    const [ page, setPage ] = useState(1)
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(5)
    const [ editedPageSize, setEditedPageSize ] = useState(5);
    const [ resultCount, setResultCount ] = useState(0);

    const [options, setOptions] = useState({"windDirections":[], "conditions":[], "species":[], "siteMarshes":[], "storageBuffers":[], "sites":[], "imageTypes":[]});
    
    const [ currentObject, setCurrentObject ] = useState({});

    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id);
        }
    };

    //community Analysis
    useEffect(() => {
      const handlecommunityAnalysis = async () => {
        try{
          let info = await getCommunityAnalysis(token, sampleID, page, pageSize)
          setCommunityAnalysis({"results":info['results']})
          setResultCount(parseInt(info["resultCount"]))
        }finally{
            setRefresh(null)
        }
      }
      try{
        handlecommunityAnalysis().then(r => {setLoading(false)})
      }catch(error){
        alert("Error contacting server")
      }
    }, [refresh, page, pageSize])

    const handleInputChange = (event, name) => {
        if(name === "primerPair"){
            setCurrentObject({
                ...currentObject,
                [name+"ID"]: event.id,
                [name]: event,
            });
        }else{
            setCurrentObject({
                ...currentObject,
                [name]: event.target.value,
            });
        }
        const updatedInformation = {
            ...communityAnalysis,
            results: communityAnalysis.results.map(item => {
                if (item.id === editMode) {
                    if(name === "primerPair"){
                        let object = JSON.parse(event.target.value)
                        return{
                            ...item,
                            [name+"ID"]: object.id,
                            [name]: object,
                        }
                    }else{
                        return {
                            ...item,
                            [name]: event.target.value
                        };
                    }
                }
                return item;
            })
        };
    
        setCommunityAnalysis(updatedInformation);

    };

    useEffect(() => {
        const fetchOptions = async () => {
            let options = await getFilterOptions(token);
            setOptions(options)
        }
        try{
            fetchOptions();
        }catch(error){
            alert("Error contacting server")
        }
    }, []);

    const deleteCommunityAnalysisRow = () => {
        deleteCommunityAnalysis(token, selectedId).then(response => {
            if(response.status !== 200){
                response.json().then(json => {
                    alert(json["message"])
                })
            }else{
                setRefresh(true)
                setSelectedId(null)
            }
        })
    }
    
    const addCommunityAnalysisRow = () => {
        setCurrentObject({"id":-1, "sampleID":sampleID})
        setEditMode(true)
        setViewMode(true)
        setSelectedId(-1)
    }

    const handleOnClickEditOrSave = () => {
        if(editMode === true){
            if(currentObject["id"] !== -1){
                updateCommunityAnalysis(token, currentObject).then(response => {
                    if(response.status !== 200){
                        response.json().then(json => {
                            alert(json["message"])
                        })
                    }else{
                        setRefresh(true)
                        setEditMode(false)
                    }
                })
            }else{
                addCommunityAnalysis(token, currentObject).then(response => {
                    if(response.status !== 200){
                        response.json().then(json => {
                            alert(json["message"])
                        })
                    }else{
                        setRefresh(true)
                        setEditMode(false)
                    }
                })
            }
        }else{
            setEditMode(true)
        }
    }

    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }
    
    return(
        <div className="container">
            {(loading || refresh) && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
            }
            {!viewMode &&
            <div className="community-table-container">
                <div className="community-table-buttons">
                    <div>
                        <button disabled={!selectedId} onClick={() => {setViewMode(true); setCurrentObject(communityAnalysis?.results.find(item => item.id === selectedId))}}>View</button>
                        <button onClick={() => {addCommunityAnalysisRow()}}>Add</button>
                        <button disabled={!selectedId} onClick={() => {deleteCommunityAnalysisRow()}}>Delete</button>
                    </div>
                    <div>
                        <button onClick={() => {handleBackClick()}}>{"<"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPage}
                            onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                            onBlur={(event) => {
                                const enteredPage = event.target.valueAsNumber || editedPage;
                                const maxPage = Math.ceil(resultCount / pageSize);
                                setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                              }}
                        />
                        <button onClick={() => {handleFowardClick()}}>{">"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPageSize}
                            onChange={(event) => {setEditedPageSize(event.target.valueAsNumber || editedPageSize)}}
                            onBlur={(event) => {
                                setPageSize(editedPageSize);
                            }}
                        />
                    </div>
                </div>
                <table className="community-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Replicate</th>
                            <th>Date Sequenced</th>
                            <th>Qubit Concentration</th>
                            <th>Nano Concentration</th>
                            <th>260280</th>
                            <th>260230</th>
                            <th>Primer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {communityAnalysis?.results.length > 0 && communityAnalysis?.results?.map((result) => (
                            <tr key={result.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedId === result.id}
                                        onChange={event => handleCheckboxChange(event, result.id)}
                                    />
                                </td>
                                <td>{result.replicate}</td>
                                <td>{result.dateSequenced?.replace("T", " ")} </td>
                                <td>{result.nanoDropConc}</td>
                                <td>{result.qubitConc}</td>
                                <td>{result._260280}</td>
                                <td>{result._260230}</td>
                                <td>{result?.primerPair.primerName}</td>
                            </tr>
                        ))}
                        {communityAnalysis?.results.length == 0 &&
                            <tr>
                                <td colSpan="8" style={{ textAlign: 'center' }}>
                                    <p>No Results</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
            </div>   
            }
            {viewMode && selectedId &&
                <div>
                    <button onClick={() => {setViewMode(false)}}>Back</button>
                    <button onClick={() => {handleOnClickEditOrSave()}}>{editMode ? "Save" : "Edit"}</button>
                    <div className="information-container">
                        <div>
                            <TextInput
                                label="Replicate"
                                value={currentObject["replicate"]} 
                                onChange={(event) => {handleInputChange(event, "replicate")}}
                                editable={editMode}
                            />
                            <DateTimeInput
                                label="Date Sequenced"
                                value={currentObject["dateSequenced"]} 
                                onChange={(event) => {handleInputChange(event, "dateSequenced")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="Qubit Concentration"
                                value={currentObject["qubitConc"]} 
                                onChange={(event) => {handleInputChange(event, "qubitConc")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="Read Count"
                                value={currentObject["readCount"]} 
                                onChange={(event) => {handleInputChange(event, "readCount")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Platform"
                                value={currentObject["platform"]} 
                                onChange={(event) => {handleInputChange(event, "platform")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="Nano Drop Concentration"
                                value={currentObject["nanoDropConc"]} 
                                onChange={(event) => {handleInputChange(event, "nanoDropConc")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="260280"
                                value={currentObject["_260280"]} 
                                onChange={(event) => {handleInputChange(event, "_260280")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="260230"
                                value={currentObject["_260230"]} 
                                onChange={(event) => {handleInputChange(event, "_260230")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Kit"
                                value={currentObject["kit"]} 
                                onChange={(event) => {handleInputChange(event, "kit")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Extraction Protocol"
                                value={currentObject["extractionProtocol"]} 
                                onChange={(event) => {handleInputChange(event, "extractionProtocol")}}
                                editable={editMode}
                            />
                            <PrimerPairInput
                                label="Primer Pair"
                                value={currentObject["primerPair"]} 
                                onChange={(event) => {handleInputChange(event, "primerPair")}}
                                editable={editMode}
                            />
                        </div>                                                                         
                    </div>
                </div>
            }
        </div>
    );

  }