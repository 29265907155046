import React from 'react';

const NumberInput = ({ value, onChange, editable, label="" }) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    if(onChange != undefined){
      if (!isNaN(inputValue)) {
        onChange(event);
      }
    }
  };

  return (
    <div className="input-alignment">
        <label className="input-alignment-item-left" htmlFor="input"><b>{label}</b></label>
        {(
          !editable ? value : 
          <input
            className="input-alignment-item-right"
            id="input"
            type="number"
            value={value}
            onChange={(event) => handleChange(event)}
          />
        )}
    </div>
  );
};

export default NumberInput;