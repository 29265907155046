import { useState, useEffect } from 'react';

import { verifyToken } from './api';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    if(tokenString === undefined || tokenString === null){
      return null
    }else{
      return JSON.parse(tokenString)["token"]
    }
  };

  const checkToken = async () => {
    const tokenString = sessionStorage.getItem('token');
    if(tokenString === undefined || tokenString === null){
      return {"valid":false}
    }
    try{
      var valid = await verifyToken(JSON.parse(tokenString)["token"])
      return valid["valid"]
    }catch(error){
      alert("Error contacting server")
    }
  }

  const [token, setToken] = useState(getToken());
  const [validToken, setValidToken] = useState(true);

  useEffect(() => {
    const fetchTokenValidity = async () => {
      const result = await checkToken();
      setValidToken(result);
    };

    fetchTokenValidity();
  }, []);

  const saveToken = userToken => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  const removeToken = () => {
    sessionStorage.removeItem('token');
  };

  return {
    token,
    setToken: saveToken,
    removeToken: removeToken,
    validToken
  }
}