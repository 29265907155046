import React from 'react';

const DateTimeInput = ({ value, onChange, editable = false, label="" }) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    if(onChange != undefined){
      onChange(event);
    }
  };

  return (
    <div className="input-alignment">
        <label className="input-alignment-item-left" htmlFor="input"><b>{label}</b></label>
        {(
          !editable ? value?.replace('T', ' ') : 
          <input
            className="input-alignment-item-right"
            id="input"
            type="datetime-local"
            value={value}
            onChange={(event) => handleChange(event)}
          />
        )}
    </div>
  );
};

export default DateTimeInput;