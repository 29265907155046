import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useToken from '../../util/useToken';

import { getSampleInformation } from '../../util/api';
import { getFilterOptions } from '../../util/api';
import { updateSample } from '../../util/api';
import NumberInput from '../NumberInput';
import DateTimeInput from '../DateTimeInput';
import DropDownInput from '../DropDownInput';
import TextInput from '../TextInput';
import RadioInput from '../RadioInput';

import { format } from 'date-fns';

import './SampleInformation.css'

export default function Sample ( { sampleID } ) {
    const [ loading, setLoading ] = useState(true);
    const loadingGIF = require('../../content/loading.gif');
    const { token, setToken, removeToken, validToken} = useToken();
    const [ sampleInformation, setSampleInformation ] = useState({});
    const [ editMode, setEditMode ] = useState(false);
    const [ refresh, setRefresh ] = useState(false);

    const [options, setOptions] = useState({"windDirections":[], "conditions":[], "species":[], "siteMarshes":[], "storageBuffers":[], "sites":[], "imageTypes":[]});

    //Sample
    useEffect(() => {
        getSampleInformation(token, sampleID).then(response => {
            response.json().then(json => {
                if(response.status !== 200){
                    alert(json["message"])
                }else{
                    setSampleInformation(json)
                }
            })
            setLoading(false)
            setRefresh(false)
        })
        
    }, [sampleID, refresh])


    useEffect(() => {
        const fetchOptions = async () => {
            let options = await getFilterOptions(token);
            setOptions(options)
        }
        fetchOptions();
    }, []);
    
    const handleInputChange = (event, name) => {
        if(name === "site"){
            let object = JSON.parse(event.target.value)
            setSampleInformation({
                ...sampleInformation,
                ["siteID"]: object.id,
                ["site"]: object,
            });
        }else if(name === "condition"){
            let object = JSON.parse(event.target.value)
            setSampleInformation({
                ...sampleInformation,
                ["conditionID"]: object.id,
                ["condition"]: object
            });
        }else if(name === "windDirection"){
            let object = JSON.parse(event.target.value)
            setSampleInformation({
                ...sampleInformation,
                ["windDirectionID"]: object.id,
                ["windDirection"]: object
            });
        }else if(name === "sampleStrategy"){
            let object = JSON.parse(event.target.value)
            setSampleInformation({
                ...sampleInformation,
                ["sampleStrategyID"]: object.id,
                ["sampleStrategy"]: object
            });
        }else{
            setSampleInformation({
                ...sampleInformation,
                [name]: event.target.value,
            });
        }
        
    };


    const handleEditModeSwitch = (mode) => {
        if(mode === true){
            updateSample(token, sampleInformation).then(response => {
                response.json().then(json => {
                    if(response.status != 200){
                        alert(json["message"])
                    }else{
                        setRefresh(true)
                        setEditMode(false)
                    }
                })
            })
        }else{
            setEditMode(true)
        }
    }

    return(
        <div className="container">
            {(loading || refresh) && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
            }
            <button onClick={() => handleEditModeSwitch(editMode)}>{!editMode ? "Edit" : "Save"}</button>
            <div className="sample-information-fields">
                <TextInput
                    label="Sample Name"
                    value={sampleInformation["sampleName"]} 
                    onChange={(event) => {handleInputChange(event, "sampleName")}}
                    editable={editMode}
                />
                <DateTimeInput
                    label="Date"
                    value={sampleInformation["date"]} 
                    onChange={(event) => {handleInputChange(event, "date")}}
                    editable={editMode}
                />
                <NumberInput
                    label="Latitude"
                    value={sampleInformation["latitude"]} 
                    onChange={(event) => {handleInputChange(event, "latitude")}}
                    editable={editMode}
                />
                <NumberInput
                    label="Longitude"
                    value={sampleInformation["longitude"]} 
                    onChange={(event) => {handleInputChange(event, "longitude")}}
                    editable={editMode}
                />
                <DropDownInput
                    label="Wind Direction"
                    value={sampleInformation["windDirection"]}
                    onChange={(event) => {handleInputChange(event, "windDirection")}}
                    options={options["windDirections"]}
                    editable={editMode}
                />
                <NumberInput
                    label="Air Temperature"
                    value={sampleInformation["airTemp"]} 
                    onChange={(event) => {handleInputChange(event, "airTemp")}}
                    editable={editMode}
                />
                <NumberInput
                    label="Water Temperature"
                    value={sampleInformation["waterTemp"]} 
                    onChange={(event) => {handleInputChange(event, "waterTemp")}}
                    editable={editMode}
                />
                <NumberInput
                    label="Wind Speed"
                    value={sampleInformation["windSpeed"]} 
                    onChange={(event) => {handleInputChange(event, "windSpeed")}}
                    editable={editMode}
                />
                <DropDownInput
                    label="Condition"
                    value={sampleInformation["condition"]}
                    onChange={(event) => {handleInputChange(event, "condition")}}
                    options={options["conditions"]}
                    editable={editMode}
                />
                <DropDownInput
                    label="Site"
                    value={sampleInformation["site"]}
                    onChange={(event) => {handleInputChange(event, "site")}}
                    options={options["sites"]}
                    editable={editMode}
                />
                <DropDownInput
                    label="Type"
                    value={sampleInformation["sampleStrategy"]}
                    onChange={(event) => {handleInputChange(event, "sampleStrategy")}}
                    options={options["strategies"]}
                    editable={editMode}
                />
                <NumberInput
                    label="Salinity"
                    value={sampleInformation["salinity"]} 
                    onChange={(event) => {handleInputChange(event, "salinity")}}
                    editable={editMode}
                />
                <TextInput
                    label="Tide Condition"
                    value={sampleInformation["tideCondition"]} 
                    onChange={(event) => {handleInputChange(event, "tideCondition")}}
                    editable={editMode}
                />      
                <TextInput
                    label="Plant Species"
                    value={sampleInformation["plantSpecies"]} 
                    onChange={(event) => {handleInputChange(event, "plantSpecies")}}
                    editable={editMode}
                />
                <TextInput
                    label="Notes"
                    value={sampleInformation["noteField"]} 
                    onChange={(event) => {handleInputChange(event, "noteField")}}
                    editable={editMode}
                />
                <RadioInput
                    label="Vegetation"
                    value={sampleInformation["vegetation"]} 
                    onChange={(event) => {handleInputChange(event, "vegetation")}}
                    editable={editMode}
                />
                <TextInput
                    label="Storage Location"
                    value={sampleInformation["storageLocation"]} 
                    onChange={(event) => {handleInputChange(event, "storageLocation")}}
                    editable={editMode}
                />
                <TextInput
                    label="Sampled By"
                    value={sampleInformation["sampledBy"]} 
                    onChange={(event) => {handleInputChange(event, "sampledBy")}}
                    editable={editMode}
                />
            </div>
        </div>
    );
  }