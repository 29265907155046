import { React, useState, useEffect } from 'react';

import TextInput from '../TextInput';
import useToken from '../../util/useToken';

import { useNavigate } from 'react-router-dom';
import { addSample } from '../../util/api';

import './NewSamplePopup.css'

export default function NewSamplePopup({open, setOpen}) {
    const [ sampleName, setSampleName ] = useState("");
    const { token, setToken, removeToken, validToken} = useToken();
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setSampleName(event.target.value)
    }

    const handleNewSample = async () => {
        try{
            addSample(token, sampleName).then(response => {
                if(response.status === 200){
                    response.json().then(json => {
                        navigate("/sampleview/"+json["id"])
                    })
                }else{
                    response.json().then(json => {
                        alert(json['message'])
                    })
                }
            })
        }catch(e){
            alert(e)
        }
    }


    return (
        <div>
            <div className="new-sample-popup">
                <div className="new-sample-popup-content">
                    <button onClick={() => setOpen(false)}>Back</button>
                    <button onClick={() => handleNewSample()}>Create</button>
                    <TextInput
                        label="Sample Name"
                        value={sampleName} 
                        onChange={(event) => {handleInputChange(event)}}
                        editable={true}
                    />
                </div>
            </div>
        </div>
    );
}