import { React, useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import ToolBar from '../toolbar/ToolBar';
import Login from '../login/Login'
import useToken from '../../util/useToken';
import FilterPopup from '../filterPopup/FilterPopup';
import SampleView from '../SampleView';
import { TextField, Button, Box } from '@material-ui/core';

import './Dashboard.css';

import { getSamples, search } from '../../util/api';
import { set } from 'date-fns';
import NewSamplePopup from '../newSamplePopup/NewSamplePopup';

export default function Dashboard() {
  const [ loading, setLoading ] = useState(false);
  const loadingGIF = require('../../content/loading.gif');
  const { token, setToken, removeToken, validToken} = useToken();
  const [ searchResults, setSearchResults] = useState([]);
  const [ isPopupOpen, setIsPopupOpen] = useState(false);
  const [ resultCount, setResultCount ] = useState(-1);
  const [ page, setPage ] = useState(1) 
  const [ pageSize, setPageSize ] = useState(5)
  const [ searchText, setSearchText] = useState('');
  const [ newSampleView, setNewSampleView ] = useState(false);
  const [ supressFirstSearch, setSupressFirstSearch ] = useState(true)
  const navigate = useNavigate();
  const [searchOptions, setSearchOptions] = useState({});

  useEffect( () => {
    let _searchOptions = sessionStorage.getItem("searchOptions")
    if(_searchOptions !== undefined && _searchOptions !== null){
      setSearchOptions(JSON.parse(_searchOptions))
    }
    let _searchResults = sessionStorage.getItem("searchResults")
    if(_searchResults !== undefined && _searchResults !== null){
      setSearchResults(JSON.parse(_searchResults))
    }
    let _searchPage = sessionStorage.getItem("searchPage")
    if(_searchPage !== undefined && _searchPage !== null){
      setPage(JSON.parse(_searchPage))
    }
    let _resultCount = sessionStorage.getItem("resultCount")
    if(_resultCount !== undefined && _resultCount !== null){
      setResultCount(JSON.parse(_resultCount))
    }
  }, [])

  useEffect( () => {
    sessionStorage.setItem("searchOptions", JSON.stringify(searchOptions))
    sessionStorage.setItem("searchResults", JSON.stringify(searchResults))
    sessionStorage.setItem("searchPage", JSON.stringify(page))
    sessionStorage.setItem("resultCount", JSON.stringify(resultCount))
  }, [searchOptions, searchResults, page, resultCount])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchOptions((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleSearch = async (searchText) => {
    setLoading(true)
    search(token, searchText, searchOptions, page, pageSize).then(results => {
      setLoading(false)
      setSearchResults(results["results"]);
      setResultCount(results["resultCount"]);
    });
  };

  const handleCardClick = (id) => {
    navigate("/sampleview/"+id)
  };

  const handleNewClick = () => {
    setNewSampleView(true)
  }

  const handleSearchText = (event) => {
    setSearchText(event.target.value)
  }

  const handleFowardClick = () => {
    setPage(page+1)
  }

  const handleBackClick = () => {
    setPage(page-1)
  }

  useEffect(() => {
    if(!supressFirstSearch){
      handleSearch(searchText)
      window.scrollTo(0, 0)
    }
    setSupressFirstSearch(false)
  }, [page])

  if(token == null || !validToken){
    return <Login />
  }

  return(
    <div className="page-temp-fix">
      {(loading) && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
      }
      <ToolBar />
      <div className="search-area">
        <FilterPopup isOpen={isPopupOpen} togglePopup={togglePopup} setSearchOptions={setSearchOptions} searchOptions={searchOptions}/>
        <div className="main-search-bar">
          <Box display="flex" justifyContent="center" alignItems="center" marginTop="calc(100vh/20)" marginBottom="calc(100vh/20)">
          <Box>
              <div className="filter-indicator">
                {Object.entries(searchOptions)?.filter(([key, value]) => value !== "")?.length > 0 && 
                  <p>*</p>
                }
                <Button variant="contained" onClick={togglePopup} style={{ marginLeft: '10px' }}>Filters</Button>
              </div>
              </Box>
              <TextField
                label="Search"
                value={searchText}
                onChange={(event) => {handleSearchText(event)}}
                style={{ marginLeft: '10px', width: 'calc(40.67vw - 20px)' }}
              />
              <Button variant="contained" onClick={() => {handleSearch(searchText);setPage(1);}} style={{ marginLeft: '10px' }}>Search</Button>
              <Button variant="contained" onClick={handleNewClick} style={{ marginLeft: '10px' }}>+</Button>
              {newSampleView && <NewSamplePopup open={newSampleView} setOpen={setNewSampleView}/>}
          </Box>
        </div>
        <div className="result-metadata">
          {resultCount > 0 && <p className="result-metadata">Page:{page}</p>}
          {resultCount != -1 && <p className="result-metadata">Results:{resultCount}</p>}
        </div>
        <div className="result-container">
          {searchResults?.map((result) => (
            <div key={result.id} className="result-list">
              <div className="result-item" onClick={() => handleCardClick(result.id)}>
                <h3>{result.sampleName}</h3>
              </div>
            </div>
          ))}
          {searchResults && 
            <div>
              {page > 1 && <button onClick={() => {handleBackClick()}}>Previous</button>}
              {resultCount > page * pageSize && <button onClick={() => {handleFowardClick()}}>Next</button>}
            </div>
            }
        </div>
      </div>
    </div>
  );
}