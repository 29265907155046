import { React, useState, useEffect } from 'react';

import { getPrimerPairs } from '../../util/api';
import { addPrimerPair } from '../../util/api';
import { deletePrimerPair } from '../../util/api';
import { updatePrimerPair } from '../../util/api';

import NumberInput from '../NumberInput';
import TextInput from '../TextInput';

import useToken from '../../util/useToken';

import './PrimerPairInput.css'

export default function PrimerPairInput ({ value, onChange, editable, label="" }) {
    const { token, setToken, removeToken, validToken} = useToken();
    const [ openPrimerList, setOpenPrimerList ] = useState(false);
    const [ primers, setPrimers ] = useState({"results":[]})
    const [ refresh, setRefresh ] = useState(true);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ addMode, setAddMode ] = useState(false);
    const [ editMode, setEditMode ] = useState(false);
    const [ firstLoad, setFirstLoad ] = useState(true);

    //Pagination variables
    const [ page, setPage ] = useState(1)
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(5)
    const [ resultCount, setResultCount ] = useState(0);

    const [ primerpairObject , setPrimerPairObject ] = useState({})

    const resetPrimerPairObject = () => {
        setPrimerPairObject({})
    }

    useEffect(() => {
        if(openPrimerList === true){
            document.body.style.overflow = 'hidden';
            return ()=> document.body.style.overflow = 'unset';
        }
     }, [openPrimerList]);
    

    useEffect(() => {
        const handlePrimerPairs = async () => {
            try{
                let info = await getPrimerPairs(token, page, pageSize)
                setPrimers(info)
                setResultCount(parseInt(info["resultCount"]))
            }finally{
                setRefresh(false)
                setFirstLoad(false)
            }
        }
        handlePrimerPairs()
    }, [refresh, page])

    const handleChange = () => {
        if(onChange != undefined){
            onChange(primers.results?.filter(item => item.id === selectedId)[0]);
        }
    };

    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id)
        }
    };

    const handlePrimerPairChange = (event, field) => {
        const newValue = event.target.value;
        setPrimerPairObject((prevState) => ({
          ...prevState,
          [field]: newValue,
        }));
      };


    const savePrimerPair = () => {
        if(addMode){
            addPrimerPair(token, primerpairObject)
                .then(err => {setRefresh(true); setAddMode(false); resetPrimerPairObject();})
                .catch(err => alert(err))
        }else if(editMode){
            updatePrimerPair(token, primerpairObject)
            .then(err => {setRefresh(true); setEditMode(false); resetPrimerPairObject();})
            .catch(err => alert(err))
        }
    }

    const handleDeletePrimerPair = () => {
        deletePrimerPair(token, selectedId)
            .then(err => {
                if(err != null){
                    console.log(err)
                    alert(err)
                }else{
                    setRefresh(true);
                    setSelectedId(null)
                }
            }).catch(err => alert(err))
    }

    const formatToFasta = (text) => {
        const sequenceIdentifier = '>\n';
    
        const chunkSize = 60;
        let formattedText = '';
        for (let i = 0; i < text?.length; i += chunkSize) {
          const chunk = text.substring(i, i + chunkSize);
          formattedText += chunk + '\n';
        }
    
        return sequenceIdentifier + formattedText;
      };

    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }

    const handleEditMode = () => {
        setPrimerPairObject(primers.results?.filter(p => p.id === selectedId)[0])
        setEditMode(true)
    }


    return (
        <div className="primer-container">
            <div className="input-alignment">
                <div className="input-alignment-item-left">
                    <label htmlFor="input"><b>{label}</b></label>
                    {editable && <button onClick={() => {setOpenPrimerList(!openPrimerList)}}>Change</button>}
                </div>
                <div className="info-container">
                    <div className="rounded-div-primerpair-info">
                        <div className="input-alignment-small" id="input">
                            <label className="input-alignment-item-small-label" htmlFor="primerName">Primer Name</label>
                            <p id="primername" className="input-alignment-item-small">{value?.primerName}</p>
                        </div>    
                        <div className="input-alignment-small" id="input">
                            <label className="input-alignment-item-small-label" htmlFor="fSeq">fSeq</label>
                            <pre id="fSeq" className="input-alignment-item-small">{formatToFasta(value?.fSeq)}</pre>
                        </div>    
                        <div className="input-alignment-small" id="input">
                            <label className="input-alignment-item-small-label" htmlFor="rSeq">rSeq</label>  
                            <pre id="rSeq" className="input-alignment-item-small">{formatToFasta(value?.rSeq)}</pre>
                        </div>
                        <div className="input-alignment-small" id="input">
                            <label className="input-alignment-item-small-label" htmlFor="pubmedID">Pubmed ID</label>
                            <p id="pubmedID" className="input-alignment-item-small">{value?.pubmedID}</p>
                        </div>
                        <div className="input-alignment-small" id="input">
                            <label className="input-alignment-item-small-label" htmlFor="DOI">DOI</label>
                            <p id="DOI" className="input-alignment-item-small">{value?.DOI}</p>
                        </div>  
                    </div>
                </div>
            </div>
            {(openPrimerList && !addMode && !editMode) && (
                <div className="primer-popup">
                    <div className="primer-popup-content">
                        <h2>Primer Pairs</h2>
                        <div>
                            <div className="primer-table-buttons">
                                <div>
                                    <button disabled={selectedId == null} onClick={() => {handleChange(); setOpenPrimerList(!openPrimerList);}}>Select</button>
                                    <button onClick={() => {setAddMode(true)}}>Add</button>
                                    <button disabled={selectedId == null} onClick={() => {handleDeletePrimerPair()}}>Delete</button>
                                    <button disabled={selectedId == null} onClick={() => {handleEditMode()}}>Edit</button>
                                </div>
                                <div>
                                    <button onClick={() => {handleBackClick()}}>{"<"}</button>
                                    <input
                                        className="page-input"
                                        type="number"
                                        value={editedPage}
                                        onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                                        onBlur={(event) => {
                                            const enteredPage = event.target.valueAsNumber || editedPage;
                                            const maxPage = Math.ceil(resultCount / pageSize);
                                            setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                            setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                          }}
                                    />
                                    <button onClick={() => {handleFowardClick()}}>{">"}</button>
                                </div>
                            </div>
                            <table className="primerpair-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Primer Name</th>
                                        <th>fSeq</th>
                                        <th>rSeq</th>
                                        <th>Pubmed ID</th>
                                        <th>DOI</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {primers?.results?.map((result) => (
                                        <tr key={result.id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedId === result.id}
                                                    onChange={event => handleCheckboxChange(event, result.id)}
                                                />
                                            </td>
                                            <td>{result.primerName} </td>
                                            <td>{result.fSeq}</td>
                                            <td>{result.rSeq}</td>
                                            <td>{result.pubmedID}</td>
                                            <td>{result.DOI}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <button onClick={() => {setOpenPrimerList(!openPrimerList)}}>Close</button>
                        {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
                    </div>
                </div>
            )}
            {(openPrimerList && (addMode || editMode)) && ( 
                <div className="primer-popup">
                    <div className="primer-popup-content">
                        <h2>Primer Pairs</h2>
                            <button onClick={() => {savePrimerPair()}}>Save</button>
                            <TextInput 
                                label="Primer Name"
                                value={primerpairObject?.primerName}
                                onChange={(event) => {handlePrimerPairChange(event, "primerName")}}
                                editable={true}
                            />
                            <TextInput
                                label="fSeq"
                                value={primerpairObject?.fSeq}
                                onChange={(event) => {handlePrimerPairChange(event, "fSeq")}}
                                editable={true}
                            />
                            <TextInput 
                                label="rSeq"
                                value={primerpairObject?.rSeq}
                                onChange={(event) => {handlePrimerPairChange(event, "rSeq")}}
                                editable={true}
                            />
                            <NumberInput 
                                label="Pubmed ID"
                                value={primerpairObject?.pubmedID}
                                onChange={(event) => {handlePrimerPairChange(event, "pubmedID")}}
                                editable={true}
                            />
                            <TextInput 
                                label="DOI"
                                value={primerpairObject?.DOI}
                                onChange={(event) => {handlePrimerPairChange(event, "DOI")}}
                                editable={true}
                            />
                    </div>    
                </div>
            )}
        </div>
    );
};

