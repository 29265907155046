import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useToken from '../../util/useToken';
import {getPermissionLevel} from '../../util/api';

import './ToolBar.css'

function ToolBar(){
    const { token, setToken, removeToken} = useToken();
    const navigate = useNavigate();
    const [showAdminTab, setShowAdminTab] = useState(false);

    useEffect(() => {
        const fetchPermissionLevel = async () => {
          const permissionLevel = await getPermissionLevel(token);
          console.log(permissionLevel)
          setShowAdminTab(permissionLevel === 1);
        };
    
        fetchPermissionLevel();
    }, [token]);

    const handleLogout = () => {
        removeToken();
        sessionStorage.removeItem("searchOptions")
        sessionStorage.removeItem("searchResults")
        sessionStorage.removeItem("searchPage")
        sessionStorage.removeItem("resultCount")
        navigate("/")
        window.location.reload();
    };

    return (
        <div className="ToolBar">
            <div className="left-buttons-container">
                <button className="clear-button" onClick={() => navigate("/dashboard")}>ForamDB</button>
            </div>
            <div className="right-buttons-container">
                {showAdminTab && <button className="clear-button" onClick={() => navigate("/admin")}>Admin</button>}
                <button className="clear-button" onClick={handleLogout}>Log Out</button>
            </div>
        </div>
      )
}

export default ToolBar;