import React, { useEffect } from 'react';

const DropDownInput = ({ value, options, onChange, editable, label="" }) => {
  const handleChange = (event) => {
    if(onChange != undefined){
      onChange(event);
    }
  };

  return (
    <div className="input-alignment">
        <label className="input-alignment-item-left" htmlFor="input"><b>{label}</b></label>
        {label === "Species" &&
        <i>
        {(
            !editable ? value?.value : 
            <select
             className="input-alignment-item-right"
              id="input"
              value={JSON.stringify(value) || ""}
              onChange={(event) => handleChange(event)}
              >
              {options?.map((option) => (
                  <option key={option.id} value={JSON.stringify(option)}>
                  {option.value}
                  </option>
              ))}
            </select>
        )}
        </i>
        }
        {label !== "Species" &&(
            !editable ? value?.value : 
            <select
             className="input-alignment-item-right"
              id="input"
              value={JSON.stringify(value) || ""}
              onChange={(event) => handleChange(event)}
              >
              {options?.map((option) => (
                  <option key={option.id} value={JSON.stringify(option)}>
                  {option.value}
                  </option>
              ))}
            </select>
        )}
    </div>
  );
};

export default DropDownInput;