import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Routes} from 'react-router-dom';

import Dashboard from './components/dashboard/Dashboard';
import Register from './components/Register';
import Admin from './components/admin/Admin';
import SampleView from './components/SampleView';
import Login from './components/login/Login';

import { GlobalStateProvider } from './util/globalcontext';

document.title = 'ForamDB';

const root = document.getElementById('root')
render(
  <GlobalStateProvider>
    <BrowserRouter baseName="/">
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/sampleview/:id" element={<SampleView />}></Route>
      </Routes>
    </BrowserRouter>
  </GlobalStateProvider>,
  root
);
