import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useToken from '../../util/useToken';

import { getCountingAnalysisInformation } from '../../util/api';
import { getFilterOptions } from '../../util/api';
import { updateCountingAnalysis } from '../../util/api';
import { deleteCountingAnalysis } from '../../util/api';
import { addCountingAnalysis } from '../../util/api';
import NumberInput from '../NumberInput';
import DateTimeInput from '../DateTimeInput';
import DropDownInput from '../DropDownInput';
import TextInput from '../TextInput';

import { format } from 'date-fns';


import './CountingAnalysis.css'

export default function CountingAnalysis ( { sampleID } ) {
    const [ loading, setLoading ] = useState(true);
    const loadingGIF = require('../../content/loading.gif');
    const { token, setToken, removeToken, validToken} = useToken();
    const [ firstLoad, setFirstLoad ] = useState(true);
    const [ countingAnalysisInformation, setCountingAnalysisInformation ] = useState({"results":[]});
    const [ editMode, setEditMode ] = useState(false);
    const [ viewMode, setViewMode ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ refresh, setRefresh ] = useState(null);

    //Pagination variables
    const [ page, setPage ] = useState(1)
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(5)
    const [ editedPageSize, setEditedPageSize ] = useState(5);
    const [ resultCount, setResultCount ] = useState(0);

    const [options, setOptions] = useState({"windDirections":[], "conditions":[], "species":[], "siteMarshes":[], "storageBuffers":[], "sites":[], "imageTypes":[]});

    const [ currentObject, setCurrentObject ] = useState({});
    
    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id);
        }
    };

    useEffect(() => {
        getCountingAnalysisInformation(token, sampleID, page, pageSize).then(response =>{
            if(response.status !== 200){
                response.json().then(json => {
                    alert(json["message"])
                })
            }else{
                response.json().then(json => {
                    setCountingAnalysisInformation({"results":json['results']})
                    setResultCount(parseInt(json["resultCount"]))
                    setRefresh(null)
                    setLoading(false)
                })
            }
        })
    }, [refresh, page, pageSize])

    const handleInputChange = (event, name) => {
        if(name === "species" || name === "storageBuffer"){
            let object = JSON.parse(event.target.value)
            setCurrentObject({
                ...currentObject,
                [name+"ID"]: object.id,
                [name]: object,
            });
        }else{
            setCurrentObject({
                ...currentObject,
                [name]: event.target.value,
            });
        }
        const updatedInformation = {
            ...countingAnalysisInformation,
            results: countingAnalysisInformation.results.map(item => {
                if (item.id === editMode) {
                    if(name === "species" || name === "storageBuffer"){
                        let object = JSON.parse(event.target.value)
                        return{
                            ...item,
                            [name+"ID"]: object.id,
                            [name]: object,
                        }
                    }else{
                        return {
                            ...item,
                            [name]: event.target.value
                        };
                    }
                }
                return item;
            })
        };
    
        setCountingAnalysisInformation(updatedInformation);

    };

    useEffect(() => {
        const fetchOptions = async () => {
            let options = await getFilterOptions(token);
            setOptions(options)
        }
        fetchOptions();
    }, []);

    const deleteCountingAnalysisRow = () => {

        deleteCountingAnalysis(token, selectedId).then(response => {
            if(response.status !== 200){
                response.json().then(json => {
                    alert(json["message"])
                })
            }else{
                setRefresh(true)
                setSelectedId(null)
            }
        })
    }
    
    const addCountingAnalysisRow = () => {
        setCurrentObject({"id":-1, "sampleID":sampleID})
        setEditMode(true)
        setViewMode(true)
        setSelectedId(-1)
    }

    const handleOnClickEditOrSave = () => {
        if(editMode == true && !validateSave()){
            const handleSave = async () => {
                if(currentObject["id"] !== -1){
                    await updateCountingAnalysis(token, currentObject); 
                }else{
                    await addCountingAnalysis(token, currentObject)
                }
                setRefresh(true)
            }
            handleSave()
            while(refresh !== null){
                setTimeout(() => {}, 2000)
            }
            setEditMode(false)
        }else{
            setEditMode(true)
        }
    }

    //Returns true if errors exist
    function validateSave(){
        let errorMessages = []
        
        
        let value = parseFloat(currentObject["cellCount"])
        if( value < 0 && !isNaN(value)){
            errorMessages.push("\"Cell Count\" must be a valid postive number.")
        }

        value = parseFloat(currentObject["storageLoc"])
        if(value < 0 && !isNaN(value)){
            errorMessages.push("\"Storage Location\" must be a valid postive number.")
        }

        value = parseFloat(currentObject["storageVolume"])
        if(value < 0 && !isNaN(value)){
            errorMessages.push("\"Storage Volume\" must be a valid postive number.")
        }
        

        for(let i in errorMessages){
            alert(errorMessages[i])
        }

        return errorMessages.length > 0
    }

    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }

    return(
        <div className="container">
            {loading && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
            }
            {!viewMode &&
            <div className="counting-table-container">
                <div className="counting-table-buttons">
                    <div>
                        <button disabled={!selectedId} onClick={() => {setViewMode(true); setCurrentObject(countingAnalysisInformation?.results.find(item => item.id === selectedId))}}>View</button>
                        <button onClick={() => {addCountingAnalysisRow()}}>Add</button>
                        <button disabled={!selectedId} onClick={() => {deleteCountingAnalysisRow()}}>Delete</button>
                    </div>
                    <div>
                        <button onClick={() => {handleBackClick()}}>{"<"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPage}
                            onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                            onBlur={(event) => {
                                const enteredPage = event.target.valueAsNumber || editedPage;
                                const maxPage = Math.ceil(resultCount / pageSize);
                                setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                              }}
                        />
                        <button onClick={() => {handleFowardClick()}}>{">"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPageSize}
                            onChange={(event) => {setEditedPageSize(event.target.valueAsNumber || editedPageSize)}}
                            onBlur={(event) => {
                                setPageSize(editedPageSize);
                            }}
                        />
                    </div>
                </div>
                <table className="counting-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date Picked</th>
                            <th>Cell Count</th>
                            <th>Storage Location</th>
                            <th><i>Species</i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {countingAnalysisInformation?.results.length > 0 && countingAnalysisInformation?.results?.map((result) => (
                            <tr key={result.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedId === result.id}
                                        onChange={event => handleCheckboxChange(event, result.id)}
                                    />
                                </td>
                                <td>{result.datePicked?.replace("T", " ")} </td>
                                <td>{result.cellCount}</td>
                                <td>{result.storageLoc}</td>
                                <td><i>{result.species?.value}</i></td>
                            </tr>
                        ))}
                        {countingAnalysisInformation?.results.length == 0 &&
                            <tr>
                                <td colSpan="8" style={{ textAlign: 'center' }}>
                                    <p>No Results</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
            </div>
            }
            {viewMode && selectedId &&
                <div>
                    <button onClick={() => {setViewMode(false)}}>Back</button>
                    <button onClick={() => {handleOnClickEditOrSave()}}>{editMode ? "Save" : "Edit"}</button>
                    <div className="information-container">
                        <div>
                            <DropDownInput
                                label="Species"
                                value={currentObject["species"]} 
                                options={options["species"]}
                                onChange={(event) => {handleInputChange(event, "species")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Description"
                                value={currentObject["description"]} 
                                onChange={(event) => {handleInputChange(event, "description")}}
                                editable={editMode}
                            />
                            <DateTimeInput
                                label="Date Picked"
                                value={currentObject["datePicked"]} 
                                onChange={(event) => {handleInputChange(event, "datePicked")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Image Link"
                                value={currentObject["imageLink"]} 
                                onChange={(event) => {handleInputChange(event, "imageLink")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="Cell Count"
                                value={currentObject["cellCount"]} 
                                onChange={(event) => {handleInputChange(event, "cellCount")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Storage Location"
                                value={currentObject["storageLoc"]} 
                                onChange={(event) => {handleInputChange(event, "storageLoc")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Storage Volume"
                                value={currentObject["storageVolume"]} 
                                onChange={(event) => {handleInputChange(event, "storageVolume")}}
                                editable={editMode}
                            />
                            <DropDownInput
                                label="Storage Buffer"
                                value={currentObject["storageBuffer"]} 
                                options={options["storageBuffers"]}
                                onChange={(event) => {handleInputChange(event, "storageBuffer")}}
                                editable={editMode}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );

  }