import { useState, useEffect } from 'react';

const env = process.env.NODE_ENV === "development" ? "http://127.0.0.1:5001" : "https://foramdb-api.uri.edu"

export async function loginUser(credentials) {
  console.log(env)
  return await fetch(env+'/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*'
    },
    mode: 'cors',
    body: JSON.stringify(credentials),
    credentials: 'include'
  })
}

export async function getPermissionLevel(token) {
    const response = await fetch(env+'/permissionLevel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': "Bearer " +token
      },
      mode: 'cors',
      body: JSON.stringify({"token":token})
    })
    const data = await response.json()
    return data["permission"]
}


export async function allUsers(token, pageNumber, pageSize) {
    const response = await fetch(env+'/allUsers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': "Bearer " +token
      },
      mode: 'cors',
      body: JSON.stringify({"token":token, "page":pageNumber, "pageSize":pageSize})
    })
    const data = await response
    return data
}

export async function allHistory(token, pageNumber, pageSize) {
  const response = await fetch(env+'/allHistory', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"token":token, "page":pageNumber, "pageSize":pageSize})
  })
  const data = await response
  return data
}


export async function updateUser(token, user) {
  const response = await fetch(env+'/updateUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"user": JSON.stringify(user)})
  })
  const data = await response
  return data
}


export async function addUser(token, user) {
  const response = await fetch(env+'/addUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"user": JSON.stringify(user)})
  })
  const data = await response
  return data
}


export async function getSamples(token, query, type) {
  const response = await fetch(env+'/getSamples', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"query": query, "type":type})
  })
  const data = await response.json()
  return data["samples"]
}

export async function getFilterOptions(token) {
  const response = await fetch(env+'/getAllOptions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: {}
  })
  const data = await response.json()
  return data
}

export async function search(token, query, options, pageNumber, pageSize) {
  console.log(options)
  const response = await fetch(env+'/search', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"query": query, "options":options, "pageNumber":pageNumber, "pageSize":pageSize})
  })
  const data = await response.json()
  return data
}

export async function getSampleInformation(token, sampleID) {
  const response = await fetch(env+'/getSampleInformation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID})
  })
  const data = await response
  return data
}

export async function getCountingAnalysisInformation(token, sampleID, page, pageSize) {
  const response = await fetch(env+'/getCountingAnalysisInformation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID, "page":page, "pageSize":pageSize})
  })
  const data = await response
  return data
}

export async function getCommunityAnalysis(token, sampleID, page, pageSize) {
  const response = await fetch(env+'/getCommunityAnalysis', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID, "page":page, "pageSize":pageSize})
  })
  const data = await response.json()
  return data
}

export async function getLongReadInformation(token, sampleID) {
  const response = await fetch(env+'/getLongReadInformation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID})
  })
  const data = await response.json()
  return data
}

export async function addSample(token, name) {
  const response = await fetch(env+'/addSample', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"name":name})
  })
  const data = await response
  return data
}

export async function updateSample(token, sampleInformation) {
  const response = await fetch(env+'/updateSample', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleInformation":JSON.stringify(sampleInformation)})
  })
  const data = await response
  return data
}

export async function updateCountingAnalysis(token, countingAnalysis) {
  const response = await fetch(env+'/updateCountingAnalysis', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"countingAnalysis":JSON.stringify(countingAnalysis)})
  })
  const data = await response
  return data
}

export async function deleteCountingAnalysis(token, countingAnalysis) {
  const response = await fetch(env+'/deleteCountingAnalysis', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"id":countingAnalysis})
  })
  const data = await response
  return data
}

export async function addCountingAnalysis(token, countingAnalysis) {
  const response = await fetch(env+'/addCountingAnalysis', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"countingAnalysis":JSON.stringify(countingAnalysis)})
  })
  const data = await response
  return data
}

export async function updateCommunityAnalysis(token, communityAnalysis) {
  const response = await fetch(env+'/updateCommunityAnalysis', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"communityAnalysis":JSON.stringify(communityAnalysis)})
  })
  const data = await response
  return data
}

export async function deleteCommunityAnalysis(token, communityAnalysis) {
  const response = await fetch(env+'/deleteCommunityAnalysis', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"id":communityAnalysis})
  })
  const data = await response
  return data
}

export async function addCommunityAnalysis(token, communityAnalysis) {
  const response = await fetch(env+'/addCommunityAnalysis', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"communityAnalysis":JSON.stringify(communityAnalysis)})
  })
  const data = await response
  return data
}

export async function getPrimerPairs(token, page, pageSize) {
  const response = await fetch(env+'/getPrimerPairs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"page":page, "pageSize":pageSize})
  })
  const data = await response.json()
  return data
}

export async function updatePrimerPair(token, primerInformation) {
  const response = await fetch(env+'/updatePrimerPair', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"primerInformation":JSON.stringify(primerInformation)})
  })
  const data = await response
  return data
}

export async function addPrimerPair(token, primerPair) {
  const response = await fetch(env+'/addPrimerPair', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"primerPair":JSON.stringify(primerPair)})
  })
  const status = await response.status
  return status
}

export async function deletePrimerPair(token, primerPairID) {
  const response = await fetch(env+'/deletePrimerPair', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"primerPairID":primerPairID})
  })
  const status = await response.status
  if(status !== 200){
    return response.text()
  }
  return null
}

export async function getGenomeAnalysis(token, sampleID, page, pageSize) {
  const response = await fetch(env+'/getGenome', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID, "page":page, "pageSize":pageSize})
  })
  const data = await response
  return data
}

export async function updateGenomeAnalysis(token, genomeAnalysis) {
  const response = await fetch(env+'/updateGenome', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"genomeAnalysis":JSON.stringify(genomeAnalysis)})
  })
  const data = await response
  return data
}

export async function deleteGenomeAnalysis(token, genomeAnalysis, sampleID) {
  const response = await fetch(env+'/deleteGenome', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"id":genomeAnalysis, "sampleID": sampleID})
  })
  const data = await response
  return data
}

export async function addGenomeAnalysis(token, genomeAnalysis) {
  const response = await fetch(env+'/addGenome', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"genomeAnalysis":JSON.stringify(genomeAnalysis)})
  })
  const data = await response
  return data
}

export async function updateSangerAnalysis(token, sangerAnalysis) {
  const response = await fetch(env+'/updateSanger', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sangerAnalysis":JSON.stringify(sangerAnalysis)})
  })
  const data = await response
  return data
}

export async function deleteSangerAnalysis(token, sangerAnalysis) {
  const response = await fetch(env+'/deleteSanger', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"id":sangerAnalysis})
  })
  const data = await response
  return data
}

export async function addSangerAnalysis(token, sangerAnalysis) {
  const response = await fetch(env+'/addSanger', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sangerAnalysis":JSON.stringify(sangerAnalysis)})
  })
  const data = await response
  return data
}

export async function getSangerAnalysisInformation(token, sampleID, genomeID, transID, page, pageSize) {
  const response = await fetch(env+'/getSanger', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID, "genomeID":genomeID, "transID":transID, "page":page, "pageSize":pageSize})
  })
  const data = await response
  return data
}

export async function updateOA(token, OA) {
  const response = await fetch(env+'/updateOA', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OA":JSON.stringify(OA)})
  })
  const data = await response
  return data
}

export async function deleteOA(token, OAID, sampleID) {
  const response = await fetch(env+'/deleteOA', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"id":OAID, "sampleID": sampleID})
  })
  const data = await response
  return data
}

export async function addOA(token, OA, sampleID) {
  const response = await fetch(env+'/addOA', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OA":JSON.stringify(OA), "sampleID":sampleID})
  })
  const data = await response
  return data
}

export async function getOAs(token, sampleID, page, pageSize) {
  const response = await fetch(env+'/getOAs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID, "page":page, "pageSize":pageSize})
  })
  const data = await response
  return data
}

export async function getOA(token, OAID) {
  const response = await fetch(env+'/getOA', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OAID":OAID})
  })
  const data = await response
  return data
}

export async function deleteOAMaintenance(token, id) {
  const response = await fetch(env+'/deleteOAMaintenance', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"id":id})
  })
  const data = await response
  return data
}

export async function deleteOATranscriptomic(token, id) {
  const response = await fetch(env+'/deleteOATranscriptomic', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"id":id})
  })
  const data = await response
  return data
}

export async function getOAMaintenances(token, OAID, page, pageSize) {
  const response = await fetch(env+'/getOAMaintenances', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OAID":OAID, "page":page, "pageSize":pageSize})
  })
  const data = await response
  return data
}

export async function updateOAMaintenance(token, OAMaintenance) {
  const response = await fetch(env+'/updateOAMaintenance', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OAMaintenance":JSON.stringify(OAMaintenance)})
  })
  const data = await response
  return data
}

export async function addOAMaintenance(token, OAMaintenance) {
  const response = await fetch(env+'/addOAMaintenance', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OAMaintenance":JSON.stringify(OAMaintenance)})
  })
  const data = await response
  return data
}

export async function getOATranscriptomics(token, OATranscriptomicID, page, pageSize) {
  const response = await fetch(env+'/getOATranscriptomics', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OAID":OATranscriptomicID, "page":page, "pageSize":pageSize})
  })
  const data = await response
  return data
}


export async function updateOATranscriptomic(token, OATranscriptomic) {
  const response = await fetch(env+'/updateOATranscriptomic', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OATranscriptomic":JSON.stringify(OATranscriptomic)})
  })
  const data = await response
  return data
}

export async function addOATranscriptomic(token, OATranscriptomic) {
  const response = await fetch(env+'/addOATranscriptomic', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OATranscriptomic":JSON.stringify(OATranscriptomic)})
  })
  const data = await response
  return data
}

export async function getSampleName(token, sampleID) {
  const response = await fetch(env+'/getSampleName', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID": sampleID})
  })
  const data = await response
  return data.text()
}

export async function getImages(token, sampleID, page, pageSize) {
  const response = await fetch(env+'/getImages', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID, "page":page, "pageSize":pageSize})
  })
  const data = await response
  return data
}

export async function updateImage(token, image) {
  const response = await fetch(env+'/updateImage', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"imageAnalysis":JSON.stringify(image)})
  })
  const data = await response
  return data
}

export async function addImage(token, image) {
  const response = await fetch(env+'/addImage', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"imageAnalysis":JSON.stringify(image)})
  })
  const data = await response
  return data
}

export async function deleteImage(token, imageID, sampleID) {
  const response = await fetch(env+'/deleteImage', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"id":imageID, "sampleID":sampleID})
  })
  const data = await response
  return data
}

export async function getOAXray(token, OAID, page, pageSize) {
  const response = await fetch(env+'/getOAXray', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OAID":OAID, "page":page, "pageSize":pageSize})
  })
  const data = await response
  return data
}

export async function updateOAXray(token, image) {
  const response = await fetch(env+'/updateOAXray', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OAXray":JSON.stringify(image)})
  })
  const data = await response
  return data
}

export async function addOAXray(token, image) {
  const response = await fetch(env+'/addOAXray', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"OAXray":JSON.stringify(image)})
  })
  const data = await response
  return data
}

export async function deleteOAXray(token, imageID) {
  const response = await fetch(env+'/deleteOAXray', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"id":imageID})
  })
  const data = await response
  return data
}

export async function getAllGenomes(token, page, pageSize) {
  const response = await fetch(env+'/getAllGenomes', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"page":page, "pageSize":pageSize})
  })
  const data = await response.json()
  return data
}

export async function addGenomeToSample(token, sampleID, genomeID) {
  const response = await fetch(env+'/addGenomeToSample', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID, "genomeID":genomeID})
  })
  const data = await response
  return data
}

export async function getAllOAs(token, page, pageSize) {
  const response = await fetch(env+'/getAllOAs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"page":page, "pageSize":pageSize})
  })
  const data = await response.json()
  return data
}

export async function addOAToSample(token, sampleID, OAID) {
  const response = await fetch(env+'/addOAToSample', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID, "OAID":OAID})
  })
  const data = await response
  return data
}

export async function getAllImages(token, page, pageSize) {
  const response = await fetch(env+'/getAllImages', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"page":page, "pageSize":pageSize})
  })
  const data = await response.json()
  return data
}

export async function addImageToSample(token, sampleID, imageID) {
  const response = await fetch(env+'/addImageToSample', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"sampleID":sampleID, "imageID":imageID})
  })
  const data = await response
  return data
}

export async function permDeleteGenome(token, ID) {
  const response = await fetch(env+'/permDeleteGenome', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"ID": ID})
  })
  const data = await response
  return data
}

export async function permDeleteOA(token, ID) {
  const response = await fetch(env+'/permDeleteOA', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"ID": ID})
  })
  const data = await response
  return data
}

export async function permDeleteImage(token, ID) {
  const response = await fetch(env+'/permDeleteImage', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"ID": ID})
  })
  const data = await response
  return data
}

export async function registerUser(credentials) {
  return fetch(env+'/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*'
    },
    mode: 'cors',
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export async function verifyToken(token) {
  if(token == null || token == undefined){
    return {'valid':false}
  }
  const response = await fetch(env+'/validateToken', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({"token":token})
  })
  const data = await response.json()
  return data
}

export async function resetPassword(username, password, newPassword, validatePassword) {
  const response = await fetch(env+'/resetPassword', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*'
    },
    mode: 'cors',
    body: JSON.stringify({"username": username, "password": password, "newPassword": newPassword, "validatePassword": validatePassword})
  })
  const data = await response
  return data
}

export async function dumpData(token) {
  const response = await fetch(env+'/dumpData', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization': "Bearer " +token
    },
    mode: 'cors',
    body: JSON.stringify({})
  })
  const data = await response
  return data
}