import React, { useState, createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '../../util/useToken';

import './Login.css'

import { loginUser } from '../../util/api';
import { set } from 'date-fns';

import { resetPassword } from '../../util/api';


export default function Login() {
    const { token, setToken, } = useToken();
    const navigate = useNavigate();
    const permissionLevel = createContext();
    const [loading, setLoading] = useState(false);
    const loadingGIF = require('../../content/loading.gif');
    const [ resetOpen, setResetOpen ] = useState(false)
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [verifyPassword, setVerifyPassword] = useState();

    const handleRegister = () => {
      navigate('/register');
    };


    const handleSubmitLogin = async e => {
        e.preventDefault();
        setLoading(true);
        const response = await loginUser({
          username,
          password
        });
        if(response.status == 200){
            let responseJSON = await response.json()
            setToken({"token":responseJSON["token"]});
            setLoading(false);
            navigate("/dashboard")
        }else{
            setLoading(false);
            alert("Incorrect username or password.")
        }
        
    }

    const handleSubmitReset = async e => {
        e.preventDefault();
        setLoading(true);
        resetPassword(username, password, newPassword, verifyPassword).then(response => {
            if(response.status !== 200){
                response.json().then(json => {
                    alert(json["message"])
                    setLoading(false);
                })
            }else{
                response.json().then(json => {
                    setResetOpen(false)
                    setLoading(false);
                })
            }
        })

    }

    return(
        <div className="Login">
            {loading && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
            }
            <div className="login-form">
                {!resetOpen && 
                    <div>
                        <h1>ForamDB</h1>
                        <form onSubmit={handleSubmitLogin}>
                            <label>
                                <p>Username</p>
                                <input type="text" autoComplete="new-password" onChange={e => setUserName(e.target.value)}/>
                            </label>
                            <label>
                                <p>Password</p>
                                <input type="password" autoComplete="new-password" onChange={e => setPassword(e.target.value)}/>
                            </label>
                            <div>
                                <button type="submit">Log In</button>
                                <button type="button" onClick={() => {setResetOpen(true)}}>Reset</button>
                            </div>
                        </form>
                    </div>
                }
                {resetOpen && 
                    <div>
                        <h4>Reset Password</h4>
                        <form onSubmit={handleSubmitReset}>
                            <label>
                                <p>Username</p>
                                <input type="text" autoComplete="new-password" onChange={e => setUserName(e.target.value)}/>
                            </label>
                            <label>
                                <p>Password</p>
                                <input type="password" autoComplete="new-password" onChange={e => setPassword(e.target.value)}/>
                            </label>
                            <label>
                                <p>New Password</p>
                                <input type="password" autoComplete="new-password" onChange={e => setNewPassword(e.target.value)}/>
                            </label>
                            <label>
                                <p>New Password</p>
                                <input type="password" autoComplete="new-password" onChange={e => setVerifyPassword(e.target.value)}/>
                            </label>
                            <div>
                                <button type="button" onClick={() => [setResetOpen(false)]}>Back</button>
                                <button type="submit">Reset</button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        </div>
    )
}