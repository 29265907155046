import { React, useEffect, useState } from 'react';

import Sanger from '../sanger/Sanger';
import Genome from '../genome/Genome';

import './GenomeAnalysis.css';


export default function GenomeAnalysis ( { sampleID } ) {
    const [ activeTab, setActiveTab ] = useState('Genome');

    
    return(
        <div className="container">
        <div className="genome-tabs">
          <h3
            className={`genome-tab ${activeTab === 'Genome' ? 'selected' : ''}`}
            onClick={() => setActiveTab('Genome')}>
            Genome
          </h3>
          <h3
            className={`genome-tab ${activeTab === 'Sanger' ? 'selected' : ''}`}
            onClick={() => setActiveTab('Sanger')}>
                
            Sanger
          </h3>
        </div>
        <div>
          {activeTab === 'Genome' &&
            <div>
                <Genome sampleID={sampleID}/>
            </div>
          }
          {activeTab === 'Sanger' &&
            <div>
                <Sanger sampleID={sampleID}/>
            </div>
          }
        </div>
      </div>
    );

}