import { React, useEffect, useState } from 'react';

import useToken from '../../util/useToken';

import { allUsers } from '../../util/api';
import { updateUser } from '../../util/api';
import { addUser } from '../../util/api';

import NumberInput from '../NumberInput';
import DateTimeInput from '../DateTimeInput';
import DropDownInput from '../DropDownInput';
import TextInput from '../TextInput';


import './Users.css'

export default function Users ( { sampleID } ) {
    const { token, setToken, removeToken, validToken} = useToken();
    const [ users, setUsers ] = useState({"results":[]});
    const [ editMode, setEditMode ] = useState(false);
    const [ viewMode, setViewMode ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ refresh, setRefresh ] = useState(null);

    //Pagination variables
    const [ page, setPage ] = useState(1)
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(15)
    const [ editedPageSize, setEditedPageSize ] = useState(15);
    const [ resultCount, setResultCount ] = useState(0);

    const [ currentObject, setCurrentObject ] = useState({});
    
    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id);
        }
    };

    useEffect(() => {
        allUsers(token, page, pageSize).then(response =>{
            if(response.status !== 200){
                response.json().then(json => {
                    alert(json["message"])
                })
            }else{
                response.json().then(json => {
                    setUsers({"results":json['results']})
                    setResultCount(parseInt(json["resultCount"]))
                    setRefresh(null)
                })
            }
        })
    }, [refresh, page])


    const handleInputChange = (event, name) => {
        setCurrentObject({
            ...currentObject,
            [name]: event.target.value,
        });
        const updatedInformation = {
            ...users,
            results: users.results.map(item => {
                if (item.id === editMode) {
                    return {
                        ...item,
                        [name]: event.target.value
                    };
                }
                return item;
            })
        };
        setUsers(updatedInformation);

    };

    
    const addUserRow = () => {
        setCurrentObject({"id":-1})
        setEditMode(true)
        setViewMode(true)
        setSelectedId(-1)
    }

    const handleOnClickEditOrSave = () => {
        if(editMode == true){
            if(currentObject["id"] !== -1){
                updateUser(token, currentObject).then(response => {
                    response.json().then(json => {
                        if(response.status !== 200){
                            alert(json["message"])
                        }else{
                            setRefresh(true)
                            setEditMode(false)
                        }
                    })
                })
            }else{
                addUser(token, currentObject).then(response => {
                    response.json().then(json => {
                        if(response.status !== 200){
                            alert(json["message"])
                        }else{
                            setRefresh(true)
                            setEditMode(false)
                        }
                    })
                })
            }
        }else{
            setEditMode(true)
        }
    }


    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }

    return(
        <div className="container">
            {!viewMode &&
            <div className="user-table-container">
                <div className="user-table-buttons">
                    <div>
                        <button disabled={!selectedId} onClick={() => {setViewMode(true); setCurrentObject(users?.results.find(item => item.id === selectedId))}}>View</button>
                        <button onClick={() => {addUserRow()}}>Add</button>
                    </div>

                    <div>
                        <button onClick={() => {handleBackClick()}}>{"<"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPage}
                            onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                            onBlur={(event) => {
                                const enteredPage = event.target.valueAsNumber || editedPage;
                                const maxPage = Math.ceil(resultCount / pageSize);
                                setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                              }}
                        />
                        <button onClick={() => {handleFowardClick()}}>{">"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPageSize}
                            onChange={(event) => {setEditedPageSize(event.target.valueAsNumber || editedPageSize)}}
                            onBlur={(event) => {
                                setPageSize(editedPageSize);
                            }}
                        />
                    </div>
                </div>
                <table className="user-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Username</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Permission</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.results.length > 0 && users?.results?.map((result) => (
                            <tr key={result.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedId === result.id}
                                        onChange={event => handleCheckboxChange(event, result.id)}
                                    />
                                </td>
                                <td>{result.username}</td>
                                <td>{result.firstName}</td>
                                <td>{result.lastName}</td>
                                <td>{result.email}</td>
                                <td>{result.permission}</td>
                            </tr>
                        ))}
                        {users?.results.length == 0 &&
                            <tr>
                                <td colSpan="6" style={{ textAlign: 'center' }}>
                                    <p>No Results</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
            </div>
            }
            {viewMode && selectedId &&
                <div>
                    <button onClick={() => {setViewMode(false)}}>Back</button>
                    <button onClick={() => {handleOnClickEditOrSave()}}>{editMode ? "Save" : "Edit"}</button>
                    <div className="information-container">
                        <div>
                            <TextInput
                                label="Username"
                                value={currentObject["username"]} 
                                onChange={(event) => {handleInputChange(event, "username")}}
                                editable={editMode}
                            />
                            {selectedId === -1 &&
                                <TextInput
                                    label="Password"
                                    value={currentObject["password"]} 
                                    onChange={(event) => {handleInputChange(event, "password")}}
                                    editable={editMode}
                                />
                            }
                            <TextInput
                                label="First Name"
                                value={currentObject["firstName"]} 
                                onChange={(event) => {handleInputChange(event, "firstName")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Last Name"
                                value={currentObject["lastName"]} 
                                onChange={(event) => {handleInputChange(event, "lastName")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Email"
                                value={currentObject["email"]} 
                                onChange={(event) => {handleInputChange(event, "email")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Permission"
                                value={currentObject["permission"]} 
                                onChange={(event) => {handleInputChange(event, "permission")}}
                                editable={editMode}
                            />
                            {selectedId !== -1 &&
                                <TextInput
                                    label="Reset Password"
                                    value={currentObject["resetPassword"]} 
                                    onChange={(event) => {handleInputChange(event, "resetPassword")}}
                                    editable={editMode}
                                />
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );

  }