import React, { useState, useEffect } from 'react';
import './FilterPopup.css';

import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Box } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import useToken from '../../util/useToken';
import { getFilterOptions } from '../../util/api';


const FilterPopup = ({ isOpen, togglePopup, setSearchOptions, searchOptions }) => {
    const { token, setToken, removeToken, validToken} = useToken();

    const [options, setOptions] = useState({});


    useEffect(() => {
        const fetchOptions = async () => {
            let options = await getFilterOptions(token);
            setOptions(options)
        }
        fetchOptions();
    }, []);
    

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setSearchOptions((prevOptions) => ({
          ...prevOptions,
          [name]: value,
        }));
    };


  return (
    <div className="sidebar">
      <div className={`popup ${isOpen ? 'open' : ''}`}>
        <div className="popup-container"></div>
        <div>
            <button onClick={togglePopup} className="popup-exit-button">Close</button>
        </div>
        <div className="popup-option-list">

            <div className="popup-option">
                <p className = "popup-label">Date Range</p>

                <input
                    type="date"
                    name="dateStart"
                    value={searchOptions.dateStart || ''}
                    onChange={handleInputChange}
                    placeholder={new Date()}
                />

                <input
                    type="date"
                    name="dateEnd"
                    value={searchOptions.dateEnd || ''}
                    onChange={handleInputChange}
                    placeholder={new Date()}
                />
            </div>

            <div className="popup-option">
                <p className = "popup-label">Salinity</p>
                <input
                    type="number"
                    name="salinityLow"
                    value={searchOptions.salinityLow || ''}
                    onChange={handleInputChange}
                />

                <input
                    type="number"
                    name="salinityHigh"
                    value={searchOptions.salinityHigh || ''}
                    onChange={handleInputChange}
                />
            </div>

            <div className="popup-option">
                <p className = "popup-label">Condition</p>
                    <select 
                    type="text"
                    name="condition"
                    value={searchOptions.condition || ''}
                    onChange={handleInputChange}>
                        {options["conditions"]?.map(value => 
                            <option key={value.id} value={value.value}> {value.value} </option>
                        )}
                    </select>
            </div>


            <div className="popup-option">
                <p className = "popup-label">Wind Direction</p>
                <select 
                type="text"
                name="windDirection"
                value={searchOptions.windDirection || ''}
                onChange={handleInputChange}>
                    {options["windDirections"]?.map(value => 
                            <option key={value.id} value={value.value}> {value.value} </option>
                        )}
                </select>
            </div>

            <div className="popup-option">
                <p className = "popup-label">Site</p>
                <select 
                type="text"
                name="sites"
                value={searchOptions.sites || ''}
                onChange={handleInputChange}>
                    {options["sites"]?.map(value => 
                            <option key={value.id} value={value.value}> {value.value} </option>
                        )}
                </select>
            </div>

            <div className="popup-option">
                <p className = "popup-label">Species</p>
                <select 
                type="text"
                name="species"
                value={searchOptions.species || ''}
                onChange={handleInputChange}>
                    {options["species"]?.map(value => 
                            <option key={value.id} value={value.value}> {value.value} </option>
                        )}
                </select>
            </div>

            <div className="popup-option">
                <p className = "popup-label">Sample Type</p>
                <select 
                type="text"
                name="sampleType"
                value={searchOptions?.sampleType || ''}
                onChange={handleInputChange}>
                    {options["sampleType"]?.map(value => 
                            <option key={value.id} value={value.value}> {value.value} </option>
                        )}
                </select>
            </div>

        </div>
      </div>
    </div>
  );
};

export default FilterPopup;