import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useToken from '../../util/useToken';

import { getOATranscriptomics } from '../../util/api';
import { updateOATranscriptomic } from '../../util/api';
import { addOATranscriptomic } from '../../util/api';
import { deleteOATranscriptomic } from '../../util/api';

import { getFilterOptions } from '../../util/api';
import NumberInput from '../NumberInput';
import DateTimeInput from '../DateTimeInput';
import DropDownInput from '../DropDownInput';
import TextInput from '../TextInput';

import { format } from 'date-fns';

import './OATranscriptomics.css'

export default function OATranscriptomic ( { OAID } ) {
    const [ loading, setLoading ] = useState(true);
    const loadingGIF = require('../../content/loading.gif');
    const { token, setToken, removeToken, validToken} = useToken();
    const [ firstLoad, setFirstLoad ] = useState(true);
    const [ OATAnalysis, setOATAnalysis ] = useState({"results":[]});
    const [ editMode, setEditMode ] = useState(false);
    const [ viewMode, setViewMode ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ refresh, setRefresh ] = useState(null);

    //Pagination variables
    const [ page, setPage ] = useState(1)
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(5)
    const [ resultCount, setResultCount ] = useState(0);

    const [options, setOptions] = useState({"windDirections":[], "conditions":[], "species":[], "siteMarshes":[], "storageBuffers":[], "sites":[], "imageTypes":[]});
    
    const [ currentObject, setCurrentObject ] = useState({});

    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id);
        }
    };

    useEffect(() => {
        getOATranscriptomics(token, OAID, page, pageSize).then(response => {
            response.json().then(json => {
                if(response.status !== 200){
                    alert(json["message"])
                }else{
                    setOATAnalysis(json)
                    setResultCount(json["resultCount"])
                }
            })
            setRefresh(false)
            setLoading(false)
        })
    }, [refresh, page])


    const handleInputChange = (event, name) => {
        setCurrentObject({
            ...currentObject,
            [name]: event.target.value,
        });
        
        const updatedInformation = {
            ...OATAnalysis,
            results: OATAnalysis.results.map(item => {
                if (item.id === editMode) {
                    return {
                        ...item,
                        [name]: event.target.value
                    };
                }
                return item;
            })
        };
    
        setOATAnalysis(updatedInformation);

    };


    useEffect(() => {
        const fetchOptions = async () => {
            let options = await getFilterOptions(token);
            setOptions(options)
        }
        fetchOptions();
    }, []);


    const deleteOATAnalysisRow = () => {
        deleteOATranscriptomic(token, selectedId).then(response => {
            response.json().then(json => {
                if(response.status !== 200){
                    alert(json["message"])
                }else{
                    setRefresh(true)
                    setSelectedId(null)
                }
            })
        })
    }
    
    const addOATAnalysisRow = () => {
        setCurrentObject({"id":-1, "oceanacidificationID":OAID})
        setEditMode(true)
        setViewMode(true)
        setSelectedId(-1)
    }

    const handleOnClickEditOrSave = () => {
        if(editMode == true){
            if(currentObject["id"] !== -1){
                updateOATranscriptomic(token, currentObject).then(response => {
                    response.json().then(json => {
                        if(response.status !== 200){
                            alert(json["message"])
                        }else{
                            setRefresh(true)
                            setEditMode(false)
                        }
                    })
                })
            }else{
                addOATranscriptomic(token, currentObject).then(response => {
                    response.json().then(json => {
                        if(response.status !== 200){
                            alert(json["message"])
                        }else{
                            setRefresh(true)
                            setEditMode(false)
                        }
                    })
                })
            }
        }else{
            setEditMode(true)
        }
    }

    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }
    
    return(
        <div>
            {(loading || refresh) && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
            }
            {!viewMode &&
            <div className="OAT-table-container">
                <div className="OAT-table-buttons">
                    <div>
                        <button disabled={!selectedId} onClick={() => {setViewMode(true); setCurrentObject(OATAnalysis?.results.find(item => item.id === selectedId))}}>View</button>
                        <button onClick={() => {addOATAnalysisRow()}}>Add</button>
                        <button disabled={!selectedId} onClick={() => {deleteOATAnalysisRow()}}>Delete</button>
                    </div>
                    <div>
                        <button onClick={() => {handleBackClick()}}>{"<"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPage}
                            onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                            onBlur={(event) => {
                                const enteredPage = event.target.valueAsNumber || editedPage;
                                const maxPage = Math.ceil(resultCount / pageSize);
                                setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                              }}
                        />
                        <button onClick={() => {handleFowardClick()}}>{">"}</button>
                    </div>    
                </div>
                <table className="OAT-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date Sequenced</th>
                            <th>Reads</th>
                            <th>Platform</th>
                        </tr>
                    </thead>
                    <tbody>
                        {OATAnalysis?.results.length > 0 && OATAnalysis?.results?.map((result) => (
                            <tr key={result.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedId === result.id}
                                        onChange={event => handleCheckboxChange(event, result.id)}
                                    />
                                </td>
                                <td>{result.dateSequenced?.replace("T", " ")} </td>
                                <td>{result.readCount}</td>
                                <td>{result.platform}</td>
                            </tr>
                        ))}
                        {OATAnalysis?.results.length == 0 &&
                            <tr>
                                <td colSpan="6" style={{ textAlign: 'center' }}>
                                    <p>No Results</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
            </div>   
            }
            {(viewMode && selectedId) &&
            <div>
                <div>
                    <button onClick={() => {setViewMode(false); setRefresh();}}>Back</button>
                    <button onClick={() => {handleOnClickEditOrSave()}}>{editMode ? "Save" : "Edit"}</button>
                </div>
                <div className="information-container">
                    <div>
                        <div>
                            <DateTimeInput
                                label="Date Sequenced"
                                value={currentObject["dateSequenced"]} 
                                onChange={(event) => {handleInputChange(event, "dateSequenced")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="Read Count"
                                value={currentObject["readCount"]} 
                                onChange={(event) => {handleInputChange(event, "readCount")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Platform"
                                value={currentObject["platform"]} 
                                onChange={(event) => {handleInputChange(event, "platform")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="Nano Drop Concentration"
                                value={currentObject["nanoDropConc"]} 
                                onChange={(event) => {handleInputChange(event, "nanoDropConc")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="260_280"
                                value={currentObject["_260280"]} 
                                onChange={(event) => {handleInputChange(event, "_260280")}}
                                editable={editMode}
                            />
                            <NumberInput
                                label="260_230"
                                value={currentObject["_260230"]} 
                                onChange={(event) => {handleInputChange(event, "_260230")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Kit"
                                value={currentObject["kit"]} 
                                onChange={(event) => {handleInputChange(event, "kit")}}
                                editable={editMode}
                            />
                            <TextInput
                                label="Extraction Protocol"
                                value={currentObject["extractionProtocol"]} 
                                onChange={(event) => {handleInputChange(event, "extractionProtocol")}}
                                editable={editMode}
                            />
                        </div>
                    </div>                          
                </div>
            </div>
            }
        </div>
    );
}