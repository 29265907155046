import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useToken from '../../util/useToken';

import { getSangerAnalysisInformation } from '../../util/api';
import { deleteSangerAnalysis } from '../../util/api';
import { updateSangerAnalysis } from '../../util/api';
import { addSangerAnalysis } from '../../util/api';

import { getFilterOptions } from '../../util/api';
import NumberInput from '../NumberInput';
import DateTimeInput from '../DateTimeInput';
import DropDownInput from '../DropDownInput';
import TextInput from '../TextInput';
import PrimerPairInput from '../inputs/PrimerPairInput';

import { format, set } from 'date-fns';

import './Sanger.css'

export default function Sanger ( { sampleID=null, genomeID=null, transID=null, setViewingSanger=null }) {
    const [ loading, setLoading ] = useState(true);
    const loadingGIF = require('../../content/loading.gif');
    const { token, setToken, removeToken, validToken} = useToken();
    const [ firstLoad, setFirstLoad ] = useState(true);
    const [ sangerAnalysis, setSangerAnalysis ] = useState({"results":[]});
    const [ editMode, setEditMode ] = useState(false);
    const [ viewMode, setViewMode ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ refresh, setRefresh ] = useState(null);

    //Pagination variables
    const [ page, setPage ] = useState(1)
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(5)
    const [ editedPageSize, setEditedPageSize ] = useState(5);
    const [ resultCount, setResultCount ] = useState(0);

    const [options, setOptions] = useState({"windDirections":[], "conditions":[], "species":[], "siteMarshes":[], "storageBuffers":[], "sites":[], "imageTypes":[]});
    
    const [ currentObject, setCurrentObject ] = useState({});

    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id);
        }
    };

    useEffect(() => {
        getSangerAnalysisInformation(token, sampleID, genomeID, transID, page, pageSize).then(response => {
            if(response.status !== 200){
                response.json().then(json => {
                   alert(json["message"])
                })
            }else{
                response.json().then(json => {
                    setSangerAnalysis(json)
                    setResultCount(json["resultCount"])
                })
            }
            setLoading(false)
            setRefresh(false)
        })

    }, [refresh, page])

    const handleInputChange = (event, name) => {
        if(name === "primerPair"){
            setCurrentObject({
                ...currentObject,
                [name+"ID"]: event.id,
                [name]: event,
            });
        }else{
            setCurrentObject({
                ...currentObject,
                [name]: event.target.value,
            });
        }
        const updatedInformation = {
            ...sangerAnalysis,
            results: sangerAnalysis.results.map(item => {
                if (item.id === editMode) {
                    if(name === "primerPair"){
                        let object = JSON.parse(event.target.value)
                        return{
                            ...item,
                            [name+"ID"]: object.id,
                            [name]: object,
                        }
                    }else{
                        return {
                            ...item,
                            [name]: event.target.value
                        };
                    }
                }
                return item;
            })
        };
    
        setSangerAnalysis(updatedInformation);

    };

    useEffect(() => {
        const fetchOptions = async () => {
            let options = await getFilterOptions(token);
            setOptions(options)
        }
        fetchOptions();
    }, []);

    const deleteSangerAnalysisRow = () => {
        deleteSangerAnalysis(token, selectedId).then(response => {
            if(response.status !== 200){
                response.json().then(json => {
                    alert(json["message"])
                })
            }else{
                setRefresh(true)
                setSelectedId(null)
            }
        })
    }
    
    const addSangerAnalysisRow = () => {
        setCurrentObject({"id":-1, "sampleID":sampleID, "genomeID":genomeID, "transID":transID})
        setEditMode(true)
        setViewMode(true)
        setSelectedId(-1)
        if(setViewingSanger !== null){
            setViewingSanger(true)
        }
    }

    const handleOnClickEditOrSave = () => {
        if(editMode === true){
            if(currentObject["id"] !== -1){
                updateSangerAnalysis(token, currentObject).then(response => [
                    response.json().then(json => {
                        if(response.status !== 200){
                            alert(json["message"])
                        }else{
                            setEditMode(false)
                            setRefresh(true)
                        }
                    })
                ])
            }else{
                addSangerAnalysis(token, currentObject).then(response => [
                    response.json().then(json => {
                        if(response.status !== 200){
                            alert(json["message"])
                        }else{
                            setEditMode(false)
                            setRefresh(true)
                        }
                    })
                ])
            }
        }else{
            setEditMode(true)
        }
    }

    const handleViewModeChange = () => {
        if(setViewingSanger !== null){
            setViewingSanger(!viewMode)
        }
        setViewMode(!viewMode)
    }
    
    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }


    return(
        <div>
            {(loading || refresh) && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
            }
            {!viewMode &&
            <div className="sanger-table-container">
                <div className="sanger-table-buttons">
                    <div>
                        <button disabled={!selectedId} onClick={() => {handleViewModeChange(); setCurrentObject(sangerAnalysis?.results.find(item => item.id === selectedId))}}>View</button>
                        <button onClick={() => {addSangerAnalysisRow()}}>Add</button>
                        <button disabled={!selectedId} onClick={() => {deleteSangerAnalysisRow()}}>Delete</button>
                    </div>
                    <div>
                        <button onClick={() => {handleBackClick()}}>{"<"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPage}
                            onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                            onBlur={(event) => {
                                const enteredPage = event.target.valueAsNumber || editedPage;
                                const maxPage = Math.ceil(resultCount / pageSize);
                                setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                              }}
                        />
                        <button onClick={() => {handleFowardClick()}}>{">"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPageSize}
                            onChange={(event) => {setEditedPageSize(event.target.valueAsNumber || editedPageSize)}}
                            onBlur={(event) => {
                                setPageSize(editedPageSize);
                            }}
                        />
                    </div>    
                </div>
                <table className="sanger-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date Sequenced</th>
                            <th>Merged Top Hit Name</th>
                            <th>Merged Top Hit Percent</th>
                            <th>Merged Top Hit Cov</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sangerAnalysis?.results.length > 0 && sangerAnalysis?.results?.map((result) => (
                            <tr key={result.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedId === result.id}
                                        onChange={event => handleCheckboxChange(event, result.id)}
                                    />
                                </td>
                                <td>{result.dateSequenced?.replace("T", " ")} </td>
                                <td>{result.mergedTopHitName}</td>
                                <td>{result.mergedTopHitPercID}</td>
                                <td>{result.mergedTopHitcov}</td>
                            </tr>
                        ))}
                        {sangerAnalysis?.results.length == 0 &&
                            <tr>
                                <td colSpan="6" style={{ textAlign: 'center' }}>
                                    <p>No Results</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
            </div>   
            }
            {viewMode && selectedId &&
                <div>
                    <button onClick={() => {handleViewModeChange(); setEditMode(false)}}>Back</button>
                    <button onClick={() => {handleOnClickEditOrSave()}}>{editMode ? "Save" : "Edit"}</button>
                    <div className="information-container">
                        <div>
                        <TextInput
                            label="Sequencing IDF"
                            value={currentObject["sequencingIDF"]} 
                            onChange={(event) => {handleInputChange(event, "sequencingIDF")}}
                            editable={editMode}
                        />
                        <TextInput
                            label="Sequencing IDR"
                            value={currentObject["sequencingIDR"]} 
                            onChange={(event) => {handleInputChange(event, "sequencingIDR")}}
                            editable={editMode}
                        /> 
                        <DateTimeInput
                            label="Date Sequenced"
                            value={currentObject["dateSequenced"]} 
                            onChange={(event) => {handleInputChange(event, "dateSequenced")}}
                            editable={editMode}
                        />
                        <NumberInput
                            label="Cell Count"
                            value={currentObject["cellCount"]} 
                            onChange={(event) => {handleInputChange(event, "cellCount")}}
                            editable={editMode}
                        />
                        <DateTimeInput
                            label="Date Extracted"
                            value={currentObject["dateExtracted"]} 
                            onChange={(event) => {handleInputChange(event, "dateExtracted")}}
                            editable={editMode}
                        />
                        <NumberInput
                            label="Concentration"
                            value={currentObject["conc"]} 
                            onChange={(event) => {handleInputChange(event, "conc")}}
                            editable={editMode}
                        />
                        <NumberInput
                            label="260280"
                            value={currentObject["_260280"]} 
                            onChange={(event) => {handleInputChange(event, "_260280")}}
                            editable={editMode}
                        />
                        <NumberInput
                            label="260230"
                            value={currentObject["_260230"]} 
                            onChange={(event) => {handleInputChange(event, "_260230")}}
                            editable={editMode}
                        />
                        <TextInput
                            label="Result Link F"
                            value={currentObject["resultLinkF"]} 
                            onChange={(event) => {handleInputChange(event, "resultLinkF")}}
                            editable={editMode}
                        />
                        <TextInput
                            label="Result Link R"
                            value={currentObject["resultLinkR"]} 
                            onChange={(event) => {handleInputChange(event, "resultLinkR")}}
                            editable={editMode}
                        />
                        <TextInput
                            label="fSeq"
                            value={currentObject["fSeq"]} 
                            onChange={(event) => {handleInputChange(event, "fSeq")}}
                            editable={editMode}
                            viewAsSeq={true}
                        />                            
                        <TextInput
                            label="rSeq"
                            value={currentObject["rSeq"]} 
                            onChange={(event) => {handleInputChange(event, "rSeq")}}
                            editable={editMode}
                            viewAsSeq={true}
                        />
                        <TextInput
                            label="Merged Seq"
                            value={currentObject["mergedSeq"]} 
                            onChange={(event) => {handleInputChange(event, "mergedSeq")}}
                            editable={editMode}
                            viewAsSeq={true}
                        />
                        <TextInput
                            label="Merged Top Hit Name"
                            value={currentObject["mergedTopHitName"]} 
                            onChange={(event) => {handleInputChange(event, "mergedTopHitName")}}
                            editable={editMode}
                        />
                        <NumberInput
                            label="Merged Top Hit Percent ID"
                            value={currentObject["mergedTopHitPercID"]} 
                            onChange={(event) => {handleInputChange(event, "mergedTopHitPercID")}}
                            editable={editMode}
                        />
                        <NumberInput
                            label="Merged Top Hit Cov"
                            value={currentObject["mergedTopHitcov"]} 
                            onChange={(event) => {handleInputChange(event, "mergedTopHitcov")}}
                            editable={editMode}
                        />
                        <PrimerPairInput
                            label="Primer Pair"
                            value={currentObject["primerPair"]} 
                            onChange={(event) => {handleInputChange(event, "primerPair")}}
                            editable={editMode}
                        />
                        <TextInput
                            label="Extracted By"
                            value={currentObject["extractedBy"]} 
                            onChange={(event) => {handleInputChange(event, "extractedBy")}}
                            editable={editMode}
                        />
                        </div>                                                                         
                    </div>
                </div>
            }
        </div>
    );

  }