import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../util/api';

import '../App.css'




export default function Register({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [validatePassword, setValidatePassword] = useState();
    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        if(password !== validatePassword){
            alert("Passwords do not match!")
        }else{
            const token = await registerUser({
            username,
            password
            });
            navigate("/");
        }
    }

    return(
        <div className='centeredForm'>
            <h1>Molecular Image DB</h1>
            <div>
                <form onSubmit={handleSubmit}>
                    <label>
                        <p>Username</p>
                        <input type="text" onChange={e => setUserName(e.target.value)}/>
                    </label>
                    <label>
                        <p>Password</p>
                        <input type="password" autoComplete="new-password" onChange={e => setPassword(e.target.value)}/>
                    </label>
                    <label>
                        <p>Repeat Password</p>
                        <input type="password" autoComplete="new-password" onChange={e => setValidatePassword(e.target.value)}/>
                    </label>
                    <div className='horizonalButtons'>
                        <button type="submit">Register</button>
                    </div>
                </form>
            </div>
        </div>
    )
}