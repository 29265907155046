import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useToken from '../../util/useToken';

import { getGenomeAnalysis } from '../../util/api';
import { updateGenomeAnalysis } from '../../util/api';
import { addGenomeAnalysis } from '../../util/api';
import { deleteGenomeAnalysis } from '../../util/api';

import { getFilterOptions } from '../../util/api';
import NumberInput from '../NumberInput';
import DateTimeInput from '../DateTimeInput';
import DropDownInput from '../DropDownInput';
import TextInput from '../TextInput';
import PrimerPairInput from '../inputs/PrimerPairInput';
import Sanger from '../sanger/Sanger';

import { format } from 'date-fns';

import AddGenome from '../addGenome/AddGenome';

import './Genome.css'

export default function Genome ( { sampleID } ) {
    const [ loading, setLoading ] = useState(true);
    const loadingGIF = require('../../content/loading.gif');
    const { token, setToken, removeToken, validToken} = useToken();
    const [ firstLoad, setFirstLoad ] = useState(true);
    const [ genomeAnalysis, setGenomeAnalysis ] = useState({"results":[]});
    const [ editMode, setEditMode ] = useState(false);
    const [ viewMode, setViewMode ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ refresh, setRefresh ] = useState(null);
    const [ viewingSanger, setViewingSanger ] = useState(false);
    const [ addGenomeOpen, setAddGenomeOpen] = useState(false);

    //Pagination variables
    const [ page, setPage ] = useState(1)
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(5)
    const [ editedPageSize, setEditedPageSize ] = useState(5);
    const [ resultCount, setResultCount ] = useState(0);

    const [options, setOptions] = useState({"windDirections":[], "conditions":[], "species":[], "siteMarshes":[], "storageBuffers":[], "sites":[], "imageTypes":[]});
    
    const [ currentObject, setCurrentObject ] = useState({});

    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id);
        }
    };

    //community Analysis
    useEffect(() => {
        getGenomeAnalysis(token, sampleID, page, pageSize).then(response => {
            if(response.status !== 200){
                response.json().then(json => {
                    alert(json["message"])
                })
            }else{
                response.json().then(json => {
                    setGenomeAnalysis(json)
                    setResultCount(json["resultCount"])
                })
            }
            setRefresh(false)
            setLoading(false)
        })
    }, [refresh, page, pageSize])


    const handleInputChange = (event, name) => {
        if(name === "primerPair"){
            setCurrentObject({
                ...currentObject,
                [name+"ID"]: event.id,
                [name]: event,
            });
        }else{
            setCurrentObject({
                ...currentObject,
                [name]: event.target.value,
            });
        }
        const updatedInformation = {
            ...genomeAnalysis,
            results: genomeAnalysis.results.map(item => {
                if (item.id === editMode) {
                    if(name === "primerPair"){
                        let object = JSON.parse(event.target.value)
                        return{
                            ...item,
                            [name+"ID"]: object.id,
                            [name]: object,
                        }
                    }else{
                        return {
                            ...item,
                            [name]: event.target.value
                        };
                    }
                }
                return item;
            })
        };
    
        setGenomeAnalysis(updatedInformation);

    };

    useEffect(() => {
        const fetchOptions = async () => {
            let options = await getFilterOptions(token);
            setOptions(options)
        }
        fetchOptions();
    }, []);

    const deleteGenomeAnalysisRow = () => {

        deleteGenomeAnalysis(token, selectedId, sampleID).then(response => {
            if(response.status !== 200){
                response.json().then(json => {
                    alert(json["message"])
                })
            }else{
                response.json().then(json => {
                    setRefresh(true)
                    setSelectedId(null)
                })
            }
        })
    }
    
    const addGenomeAnalysisRow = () => {
        setCurrentObject({"id":-1, "sampleID":sampleID})
        setEditMode(true)
        setViewMode(true)
        setSelectedId(-1)
    }

    const handleOnClickEditOrSave = () => {
        if(editMode === true){
            if(currentObject["id"] !== -1){
                updateGenomeAnalysis(token, currentObject).then(response => {
                    if(response.status !== 200){
                        response.json().then(json => {
                            alert(json["message"])
                        })
                    }else{
                        response.json().then(json => {
                            setRefresh(true)
                            setEditMode(false)
                        })
                    }
                })
            }else{
                addGenomeAnalysis(token, currentObject).then(response => {
                    if(response.status !== 200){
                        response.json().then(json => {
                            alert(json["message"])
                        })
                    }else{
                        response.json().then(json => {
                            setRefresh(true)
                            setEditMode(false)
                        })
                    }
                })
            }
        }else{
            setEditMode(true)
        }
    }

    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }
    
    return(
        <div>
            {(loading || refresh) && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
            }
            {!viewMode &&
            <div className="genome-table-container">
                {addGenomeOpen && <AddGenome sampleID={sampleID} open={addGenomeOpen} setOpen={setAddGenomeOpen} setRefresh={setRefresh}/>}
                <div className="genome-table-buttons">
                    <div>
                        <button disabled={!selectedId} onClick={() => {setViewMode(true); setCurrentObject(genomeAnalysis?.results.find(item => item.id === selectedId))}}>View</button>
                        <button onClick={() => {addGenomeAnalysisRow()}}>Add</button>
                        <button className="genome-table-large-button" onClick={() => {setAddGenomeOpen(true)}}>Add Existing</button>
                        <button disabled={!selectedId} onClick={() => {deleteGenomeAnalysisRow()}}>Delete</button>
                    </div>
                    <div>
                        <button onClick={() => {handleBackClick()}}>{"<"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPage}
                            onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                            onBlur={(event) => {
                                const enteredPage = event.target.valueAsNumber || editedPage;
                                const maxPage = Math.ceil(resultCount / pageSize);
                                setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                              }}
                        />
                        <button onClick={() => {handleFowardClick()}}>{">"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPageSize}
                            onChange={(event) => {setEditedPageSize(event.target.valueAsNumber || editedPageSize)}}
                            onBlur={(event) => {
                                setPageSize(editedPageSize);
                            }}
                        />
                    </div>    
                </div>
                <table className="genome-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date Sequenced</th>
                            <th>Data Location</th>
                            <th>Sangers</th>
                        </tr>
                    </thead>
                    <tbody>
                        {genomeAnalysis?.results.length > 0 && genomeAnalysis?.results?.map((result) => (
                            <tr key={result.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedId === result.id}
                                        onChange={event => handleCheckboxChange(event, result.id)}
                                    />
                                </td>
                                <td>{result.dateSequenced?.replace("T", " ")} </td>
                                <td>{result.dataLocation}</td>
                                <td>{result.sangerCount}</td>
                            </tr>
                        ))}
                        {genomeAnalysis?.results.length == 0 &&
                            <tr>
                                <td colSpan="6" style={{ textAlign: 'center' }}>
                                    <p>No Results</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
            </div>   
            }
            {viewMode && selectedId &&
                <div>
                    {!viewingSanger &&
                        <div>
                            <button onClick={() => {setViewMode(false); setRefresh(); setEditMode(false)}}>Back</button>
                            <button onClick={() => {handleOnClickEditOrSave()}}>{editMode ? "Save" : "Edit"}</button>
                        </div>
                    }
                    <div className="information-container">
                        <div>
                        {!viewingSanger &&
                            <div>
                                <DateTimeInput
                                    label="Date Sequenced"
                                    value={currentObject["dateSequenced"]} 
                                    onChange={(event) => {handleInputChange(event, "dateSequenced")}}
                                    editable={editMode}
                                />
                                <NumberInput
                                    label="Qubit Conc"
                                    value={currentObject["qubitConc"]} 
                                    onChange={(event) => {handleInputChange(event, "qubitConc")}}
                                    editable={editMode}
                                />
                                <NumberInput
                                    label="Read Count"
                                    value={currentObject["readCount"]} 
                                    onChange={(event) => {handleInputChange(event, "readCount")}}
                                    editable={editMode}
                                />
                                <TextInput
                                    label="Platform"
                                    value={currentObject["platform"]} 
                                    onChange={(event) => {handleInputChange(event, "platform")}}
                                    editable={editMode}
                                />
                                <NumberInput
                                    label="Nano Drop Conc"
                                    value={currentObject["nanoDropConc"]} 
                                    onChange={(event) => {handleInputChange(event, "nanoDropConc")}}
                                    editable={editMode}
                                />
                                <TextInput
                                    label="Data Location"
                                    value={currentObject["dataLocation"]} 
                                    onChange={(event) => {handleInputChange(event, "dataLocation")}}
                                    editable={editMode}
                                />
                                <NumberInput
                                    label="260_280"
                                    value={currentObject["_260_280"]} 
                                    onChange={(event) => {handleInputChange(event, "_260_280")}}
                                    editable={editMode}
                                />
                                <NumberInput
                                    label="260_230"
                                    value={currentObject["_260_230"]} 
                                    onChange={(event) => {handleInputChange(event, "_260_230")}}
                                    editable={editMode}
                                />
                                <NumberInput
                                    label="Median Fragment Size"
                                    value={currentObject["medianFragmentSize"]} 
                                    onChange={(event) => {handleInputChange(event, "medianFragmentSize")}}
                                    editable={editMode}
                                />
                                <NumberInput
                                    label="25th Fragment Size"
                                    value={currentObject["_25thPercentFragmentSize"]} 
                                    onChange={(event) => {handleInputChange(event, "_25thPercentFragmentSize")}}
                                    editable={editMode}
                                />
                                <NumberInput
                                    label="75th Fragment Size"
                                    value={currentObject["_75thPercentFragmentSize"]} 
                                    onChange={(event) => {handleInputChange(event, "_75thPercentFragmentSize")}}
                                    editable={editMode}
                                />
                            </div>
                        }
                        
                            <div className="genome-sanger-container">
                                {(!viewingSanger && currentObject["id"] !== -1) && <h3>Associated Sangers</h3> }
                                {currentObject["id"] !== -1 &&
                                    <Sanger genomeID={currentObject.id} sampleID={sampleID} setViewingSanger={setViewingSanger}/> 
                                }
                            </div>
                        </div>                                  
                    </div>
                </div>
            }
        </div>
    );

  }