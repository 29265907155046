import { React, useEffect, useState } from 'react';

import ToolBar from '../toolbar/ToolBar';

import Users from '../users/Users';
import Activity from '../activity/Activity';
import DeleteGenome from '../deleteGenome/DeleteGenome';
import DeleteOA from '../deleteOA/DeleteOA';
import DeleteImage from '../deleteImage/DeleteImage';

import { dumpData } from '../../util/api';
import useToken from '../../util/useToken';

import './Admin.css';



export default function Admin () {
    const [ activeTab, setActiveTab ] = useState('users');
    const { token, setToken, removeToken, validToken} = useToken();



    const dumpDataFile = () => {
      try{
        dumpData(token).then(response => {
          if(response.status !== 200){
            response.json().then(json => {
              alert(json["message"])
            })
          }else{
            response.blob().then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'exported_data.zip';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            })
          }
        })
      }catch(error){
        alert(error)
      }
    }

    
    return(
      <div className="page-temp-fix">
        <ToolBar />
          <div className="admin-tabs">
            <h3
              className={`admin-tab ${activeTab === 'users' ? 'selected' : ''}`}
              onClick={() => setActiveTab('users')}>
              Users
            </h3>
            <h3
              className={`admin-tab ${activeTab === 'activity' ? 'selected' : ''}`}
              onClick={() => setActiveTab('activity')}>
              Activity
            </h3>
            <h3
              className={`admin-tab ${activeTab === 'delete' ? 'selected' : ''}`}
              onClick={() => setActiveTab('delete')}>
              Perm Delete
            </h3>
            <h3
              className={`admin-tab ${activeTab === 'dump' ? 'selected' : ''}`}
              onClick={() => setActiveTab('dump')}>
              Data Dump
            </h3>
          </div>
          <div className="admin-view-container">
            {activeTab === 'users' &&
              <div>
                  <Users />
              </div>
            }
            {activeTab === 'activity' &&
              <div>
                  <Activity />
              </div>
            }
            {activeTab === 'delete' &&
              <div>
                  <h3><i> Warning: Will effect all samples associated with deleted entity.  All children enitites may be deleted.  Not recoverable once deleted.</i></h3>
                    <DeleteImage />
                    <DeleteGenome />
                    <DeleteOA />
              </div>
            }
            {activeTab === 'dump' &&
              <div>
                  <button onClick={() => {dumpDataFile()}}>Download</button>
              </div>
            }
          </div> 
      </div>
    );

}