import { React, useState, useEffect } from 'react';


import { getAllGenomes } from '../../util/api';
import { addGenomeToSample } from '../../util/api';

import useToken from '../../util/useToken';

import './AddGenome.css'

export default function AddGenome ({ sampleID, open, setOpen, setRefresh }) {
    const { token, setToken, removeToken, validToken} = useToken();
    const [ genomes, setGenomes ] = useState({"results":[]})
    const [ selectedId, setSelectedId ] = useState(null);

    //Pagination variables
    const [ page, setPage ] = useState(1) 
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(5)
    const [ resultCount, setResultCount ] = useState(0);


    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'unset';
     }, []);
    

    useEffect(() => {
        const handleGenomes = async () => {
            let info = await getAllGenomes(token, page, pageSize)
            setGenomes(info)
            setResultCount(parseInt(info["resultCount"]))

        }
        handleGenomes()
    }, [page])


    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id)
        }
    };

    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }

    const handleSelect = () => {
        addGenomeToSample(token, sampleID, selectedId).then(response => {
            if(response.status === 200){
                setOpen(false)
                setRefresh(true)
            }else{
                response.json().then(json => {
                    alert(json["message"]);
                })
            }
        })
    }


    return (
            <div className="genome-popup">
                <div className="genome-popup-content">
                    <h2>Genomes</h2>
                    <div>
                        <div className="genome-table-buttons">
                            <div>
                                <button disabled={selectedId == null} onClick={() => {handleSelect();}}>Select</button>
                            </div>
                            <div>
                                <button onClick={() => {handleBackClick()}}>{"<"}</button>
                                <input
                                    className="page-input"
                                    type="number"
                                    value={editedPage}
                                    onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                                    onBlur={(event) => {
                                        const enteredPage = event.target.valueAsNumber || editedPage;
                                        const maxPage = Math.ceil(resultCount / pageSize);
                                        setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                        setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                    }}
                                />
                                <button onClick={() => {handleFowardClick()}}>{">"}</button>
                            </div>
                        </div>
                        <table className="genome-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Date Sequenced</th>
                                    <th>Data Location</th>
                                    <th>Sangers</th>
                                </tr>
                            </thead>
                            <tbody>
                                {genomes?.results?.map((result) => (
                                    <tr key={result.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedId === result.id}
                                                onChange={event => handleCheckboxChange(event, result.id)}
                                            />
                                        </td>
                                        <td>{result.dateSequenced?.replace("T", " ")} </td>
                                        <td>{result.dataLocation}</td>
                                        <td>{result.sangerCount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <button onClick={() => {setOpen(false)}}>Close</button>
                    {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
                </div>
            </div>
    );
};

