import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ToolBar from './toolbar/ToolBar';
import Login from './login/Login'
import useToken from '../util/useToken';
import Sample from './sampleInformation/SampleInformation';
import CountingAnalysis from './countingAnalysis/CountingAnalysis';

import { getSampleName } from '../util/api';
import CommunityAnalysis from './communityAnalysis/CommunityAnalysis';
import GenomeAnalysis from './genomeAnalysis/GenomeAnalysis';
import OAAnalysis from './oa/OA';
import ImageAnalysis from './imageAnalysis/ImageAnalysis';

export default function SampleView() {
  const { token, setToken, removeToken, validToken} = useToken();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [ view, setView ] = useState(1);
  const navigate = useNavigate();
  const [sampleName, setSampleName] = useState("");
  const [ selectedItem, setSelectedItem ] = useState("sample information");

  useEffect(() => {
    getSampleName(token, id).then(result => {setSampleName(result);});
  }, []);

  const handleMenuSelection = (selection) => {
    setView(selection);
    setSelectedItem(selection);
  }

  const renderInformationalView = () => {
    switch(view){
      case "counting analysis":
        return(
          <CountingAnalysis sampleID={id}/>
        )
      case "community analysis":
        return(
          <CommunityAnalysis sampleID={id}/>
        )
      case "genome analysis":
        return(
          <GenomeAnalysis sampleID={id}/>
        )
      case "images":
        return(
          <ImageAnalysis sampleID={id}/>
        )
      case "oa":
        return (
          <OAAnalysis sampleID={id}/>
        )
      case "sample information":
      default:  
        return(
          <Sample sampleID={id}/>
        );     
    }
  }

  return(
    <div className="page-temp-fix">
        <ToolBar />
        <div className="sample-information-page">
            <div className="sample-information-menu">
              <div className="sample-information-menu-header">
                <button onClick={() => navigate("/dashboard")}>Results</button>
                <h4>{sampleName}</h4>
              </div>
              <p className={`listItem ${selectedItem === 'sample information' ? 'selected' : ''}`} onClick={() => handleMenuSelection("sample information")}>Information</p>
              <p className={`listItem ${selectedItem === 'counting analysis' ? 'selected' : ''}`} onClick={() => handleMenuSelection("counting analysis")}>Picking</p>
              <p className={`listItem ${selectedItem === 'community analysis' ? 'selected' : ''}`} onClick={() => handleMenuSelection("community analysis")}>Community</p>
              <p className={`listItem ${selectedItem === 'genome analysis' ? 'selected' : ''}`} onClick={() => handleMenuSelection("genome analysis")}>Genomics</p>
              <p className={`listItem ${selectedItem === 'images' ? 'selected' : ''}`} onClick={() => handleMenuSelection("images")}>Images</p>
              <p className={`listItem ${selectedItem === 'oa' ? 'selected' : ''}`} onClick={() => handleMenuSelection("oa")}>Ocean Acidification</p>
              <div className='fill'></div>
            </div>
            <div className="view-container">
                {renderInformationalView()}
            </div>
        </div>
    </div>
  );
}