import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useToken from '../../util/useToken';

import { getOAs } from '../../util/api';
import { getOA } from '../../util/api';
import { addOA } from '../../util/api';
import { updateOA } from '../../util/api';
import { deleteOA } from '../../util/api';

import NumberInput from '../NumberInput';
import DateTimeInput from '../DateTimeInput';
import DropDownInput from '../DropDownInput';
import TextInput from '../TextInput';
import { getFilterOptions } from '../../util/api';
import OAExperiment from '../oaExperiment/OAExperiment';

import AddOA from '../addOA/AddOA';

import { format } from 'date-fns';


import './OA.css'

export default function OAAnalysis ( { sampleID } ) {
    const [ loading, setLoading ] = useState(true);
    const loadingGIF = require('../../content/loading.gif');
    const { token, setToken, removeToken, validToken} = useToken();
    const [ firstLoad, setFirstLoad ] = useState(true);
    const [ OAAnalysis, setOAAnalysis ] = useState({"results":[]});
    const [ editMode, setEditMode ] = useState(false);
    const [ viewMode, setViewMode ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ refresh, setRefresh ] = useState(null);
    const [ addOAOpen, setAddOAOpen ] = useState(false);

    //Pagination variables
    const [ page, setPage ] = useState(1)
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(5)
    const [ editedPageSize, setEditedPageSize ] = useState(5);
    const [ resultCount, setResultCount ] = useState(0);

    const [options, setOptions] = useState({"windDirections":[], "conditions":[], "species":[], "siteMarshes":[], "storageBuffers":[], "sites":[], "imageTypes":[]});

    const [ currentObject, setCurrentObject ] = useState({});
    
    const handleCheckboxChange = (event, id) => {
        if(id === selectedId){
            setSelectedId(null)
        }else{
            setSelectedId(id);
        }
    };

    const handleReturnToList = () => {
        setEditMode(false)
        setViewMode(false)
        setSelectedId(null)
        setRefresh(true)
    }

    //OA Analysis
    useEffect(() => {
        getOAs(token, sampleID, page, pageSize).then(response => {
            response.json().then(json => {
                if(response.status !== 200){
                    alert(json['message'])
                }else{
                    setOAAnalysis(json)
                    setResultCount(json["resultCount"])
                }
            })
            setRefresh(false)
            setLoading(false)
        })
    }, [refresh, page])

    const handleInputChange = (event, name) => {
        if(name === "species" || name === "storageBuffer"){
            let object = JSON.parse(event.target.value)
            setCurrentObject({
                ...currentObject,
                [name+"ID"]: object.id,
                [name]: object,
            });
        }else{
            setCurrentObject({
                ...currentObject,
                [name]: event.target.value,
            });
        }
        const updatedInformation = {
            ...OAAnalysis,
            results: OAAnalysis.results.map(item => {
                if (item.id === editMode) {
                    if(name === "species" || name === "storageBuffer"){
                        let object = JSON.parse(event.target.value)
                        return{
                            ...item,
                            [name+"ID"]: object.id,
                            [name]: object,
                        }
                    }else{
                        return {
                            ...item,
                            [name]: event.target.value
                        };
                    }
                }
                return item;
            })
        };
    
        setOAAnalysis(updatedInformation);

    };


    useEffect(() => {
        const fetchOptions = async () => {
            let options = await getFilterOptions(token);
            setOptions(options)
        }
        fetchOptions();
    }, []);


    const deleteOAAnalysisRow = () => {
        deleteOA(token, selectedId, sampleID).then(response => {
            response.json().then(json => {
                if(response.status !== 200){
                    alert(json["message"])
                }else{
                    setRefresh(true)
                    setSelectedId(null)
                }
            })
        })
    }
    
    const addOAAnalysisRow = () => {
        setCurrentObject({"id":-1, "sampleID":sampleID})
        setEditMode(true)
        setViewMode(true)
        setSelectedId(-1)
    }

    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }

    return(
        <div className="container">
            {(loading || refresh) && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
            }
            {!viewMode &&
            <div className="OA-table-container">
                {addOAOpen && <AddOA sampleID={sampleID} open={addOAOpen} setOpen={setAddOAOpen} setRefresh={setRefresh}/>}
                <div className="OA-table-buttons">
                    <div>
                        <button disabled={!selectedId} onClick={() => {setViewMode(true); setCurrentObject(OAAnalysis?.results.find(item => item.id === selectedId))}}>View</button>
                        <button onClick={() => {addOAAnalysisRow()}}>Add</button>
                        <button className="OA-table-large-button" onClick={() => {setAddOAOpen(true)}}>Add Existing</button>
                        <button disabled={!selectedId} onClick={() => {deleteOAAnalysisRow()}}>Delete</button>
                    </div>
                    <div>
                        <button onClick={() => {handleBackClick()}}>{"<"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPage}
                            onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                            onBlur={(event) => {
                                const enteredPage = event.target.valueAsNumber || editedPage;
                                const maxPage = Math.ceil(resultCount / pageSize);
                                setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                            }}
                            />
                        <button onClick={() => {handleFowardClick()}}>{">"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPageSize}
                            onChange={(event) => {setEditedPageSize(event.target.valueAsNumber || editedPageSize)}}
                            onBlur={(event) => {
                                setPageSize(editedPageSize);
                            }}
                        />
                    </div>    
                </div>
                <table className="OA-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>PH Treatment</th>
                            <th>Maintenances</th>
                            <th>Transcriptomics</th>
                            <th>X-Rays</th>
                        </tr>
                    </thead>
                    <tbody>
                        {OAAnalysis?.results.length > 0 && OAAnalysis?.results?.map((result) => (
                            <tr key={result.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedId === result.id}
                                        onChange={event => handleCheckboxChange(event, result.id)}
                                    />
                                </td>
                                <td>{result.name}</td>
                                <td>{result.phTreatment}</td>
                                <td>{result.OAMaintenances?.length}</td>
                                <td>{result.transcriptomics?.length}</td>
                                <td>{result.OAXrays?.length}</td>
                            </tr>
                        ))}
                        {OAAnalysis?.results.length == 0 &&
                            <tr>
                                <td colSpan="6" style={{ textAlign: 'center' }}>
                                    <p>No Results</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
            </div>
            }
            {viewMode && selectedId &&
                <div>
                    <button onClick={() => {handleReturnToList()}}>Up To OAs</button>
                    <OAExperiment sampleID={sampleID} OAID={selectedId} setOAID={setSelectedId} />
                </div>
            }
        </div>
    );

  }