import { React, useEffect, useState } from 'react';
import NumberInput from '../NumberInput';
import TextInput from '../TextInput';
import useToken from '../../util/useToken';

import { getOA } from '../../util/api';
import { updateOA } from '../../util/api';
import { addOA } from '../../util/api';

import OAMaintenance from '../oamaintenance/OAMaintenance';
import OATranscriptomic from '../oatranscriptomics/OATranscriptomics';
import OAXray from '../oaxray/OAXray';

import './OAExperiment.css';


export default function OAExperiment ( { sampleID, OAID, setOAID } ) {
  const [ loading, setLoading ] = useState(true);
  const loadingGIF = require('../../content/loading.gif');
  const { token, setToken, removeToken, validToken} = useToken();
  const [ refresh, setRefresh ] = useState(false);
  const [ firstLoad, setFirstLoad ] = useState(true);
  const [ activeTab, setActiveTab ] = useState('Maintenances');
  const [ editMode, setEditMode ] = useState(false);

  const [options, setOptions] = useState({"windDirections":[], "conditions":[], "species":[], "siteMarshes":[], "storageBuffers":[], "sites":[], "imageTypes":[]});

    const [ OA, setOA ] = useState({})

  useEffect(() => {
    if(OAID !== -1 && OAID !== undefined){
      getOA(token, OAID).then(response => {
        response.json().then(json => {
          if(response.status !== 200){
            alert(json["message"])
          }else{
            setOA(json)
          }
        })
        setLoading(false)
      })
    }
    if(OAID === -1){
      setEditMode(true)
      setLoading(false)
    }
  }, [refresh])

  const handleInputChange = (event, name) => {
        setOA({
            ...OA,
            [name]: event.target.value,
        });
    };


    const handleOnClickEditOrSave = () => {
      if(editMode == true){
        if(OAID !== -1 && OAID !== undefined){
          updateOA(token, OA).then(response => {
            response.json().then(json => {
              if(response.status !== 200){
                alert(json["message"])
              }else{
                setRefresh(true)
                setEditMode(false)
              }
            })
          })
        }else{
          addOA(token, OA, sampleID).then(response => {
            response.json().then(json => {
              setOAID(parseInt(json["OAID"]))
              setRefresh(true)
              setEditMode(false)
            })
          })
        }
      }else{
        setEditMode(true)
      }
    }

    
    return(
        <div>
          {(loading) && 
                <div className="loading-position">
                    <img src={loadingGIF}></img>
                </div>
          }
          <button onClick={() => {handleOnClickEditOrSave()}}>{editMode ? "Save" : "Edit"}</button>
          <NumberInput 
              label="PH"
              value={OA["phTreatment"]}
              onChange={(event) => {handleInputChange(event, "phTreatment")}}
              editable={editMode}
          />
          <TextInput 
              label="Name"
              value={OA["name"]}
              onChange={(event) => {handleInputChange(event, "name")}}
              editable={editMode}
          />
          {OAID !== -1 &&
            <div className="oa-tabs">
              <h3
                className={`oa-tab ${activeTab === 'Maintenances' ? 'selected' : ''}`}
                onClick={() => setActiveTab('Maintenances')}>
                Maintenance
              </h3>
              <h3
                className={`oa-tab ${activeTab === 'Transcriptomics' ? 'selected' : ''}`}
                onClick={() => setActiveTab('Transcriptomics')}>
                Transcriptomic
              </h3>
              <h3
                className={`oa-tab ${activeTab === 'X-Rays' ? 'selected' : ''}`}
                onClick={() => setActiveTab('X-Rays')}>
                X-Rays
              </h3>
            </div>
          }
          {OAID !== -1 &&
            <div className="content">
              {activeTab === 'Maintenances' &&
                <div>
                    <OAMaintenance OAID={OAID} />
                </div>
              }
              {activeTab === 'Transcriptomics' &&
                <div>
                    <OATranscriptomic OAID={OAID} />
                </div>
              }
              {activeTab === 'X-Rays' &&
                <div>
                    <OAXray OAID={OAID} />
                </div>
              }
            </div>
          }
      </div>
    );

}