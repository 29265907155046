import { React, useEffect, useState } from 'react';

import useToken from '../../util/useToken';

import { allHistory } from '../../util/api';

import './Activity.css'

export default function Activity ( { sampleID } ) {
    const { token, setToken, removeToken, validToken} = useToken();
    const [ events, setEvents ] = useState({"results":[]});
    const [ refresh, setRefresh ] = useState(null);

    //Pagination variables
    const [ page, setPage ] = useState(1)
    const [ editedPage, setEditedPage] = useState(1);
    const [ pageSize, setPageSize ] = useState(15)
    const [ editedPageSize, setEditedPageSize ] = useState(15);
    const [ resultCount, setResultCount ] = useState(0);
    

    useEffect(() => {
        allHistory(token, page, pageSize).then(response =>{
            if(response.status !== 200){
                response.json().then(json => {
                    alert(json["message"])
                })
            }else{
                response.json().then(json => {
                    setEvents({"results":json['results']})
                    setResultCount(parseInt(json["resultCount"]))
                    setRefresh(null)
                })
            }
        })
    }, [refresh, page, pageSize])


    const handleFowardClick = () => {
        if(page < (resultCount / pageSize)){
            setPage(page + 1)
        }
    }

    const handleBackClick = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }

    return(
        <div className="container">
            <div className="activity-table-container">
                <div className="activity-table-buttons">
                    <div>
                        <button onClick={() => {handleBackClick()}}>{"<"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPage}
                            onChange={(event) => {setEditedPage(event.target.valueAsNumber || editedPage)}}
                            onBlur={(event) => {
                                const enteredPage = event.target.valueAsNumber || editedPage;
                                const maxPage = Math.ceil(resultCount / pageSize);
                                setPage(Math.max(1, Math.min(enteredPage, maxPage)));
                                setEditedPage(Math.max(1, Math.min(enteredPage, maxPage)));
                              }}
                        />
                        <button onClick={() => {handleFowardClick()}}>{">"}</button>
                        <input
                            className="page-input"
                            type="number"
                            value={editedPageSize}
                            onChange={(event) => {setEditedPageSize(event.target.valueAsNumber || editedPageSize)}}
                            onBlur={(event) => {
                                setPageSize(editedPageSize);
                            }}
                        />
                    </div>
                </div>
                <table className="activity-table">
                    <thead>
                        <tr>
                            <th>username</th>
                            <th>Date</th>
                            <th>Function</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events?.results.length > 0 && events?.results?.map((result) => (
                            <tr key={result.id}>
                                <td>{result.username}</td>
                                <td>{result.date?.replace("T", " ")}</td>
                                <td>{result.function}</td>
                                <td>{result.status}</td>
                            </tr>
                        ))}
                        {events?.results.length == 0 &&
                            <tr>
                                <td colSpan="6" style={{ textAlign: 'center' }}>
                                    <p>No Results</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {resultCount !== 0 ? <p>{page}/{Math.ceil(resultCount/pageSize)}</p> : <p></p>}
            </div>
        </div>
    );

  }