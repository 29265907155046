import React from 'react';

const RadioInput = ({ value, onChange, editable, label="" }) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    if(inputValue === "True"){
        event.target.value = "False";
    }else{
        event.target.value = "True";
    }
    if(onChange != undefined){
        onChange(event);
    }
  };

  return (
    <div className="input-alignment">
        <label className="input-alignment-item-left" htmlFor="input"><b>{label}</b></label>
        {(
          !editable ? value : 
          <input
            className="input-alignment-item-right"
            id="input"
            type="checkbox"
            checked={value === "True"}
            onChange={(event) => handleChange(event)}
          />
        )}
    </div>
  );
};

export default RadioInput;